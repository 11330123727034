<template>
  <div id="configuration-email-tab">
      <div class="vx-row mt-4">
        <div class="vx-col md:w-1/2 w-full mt-0">
          <h3 class="mt-2">Configuração de E-mail</h3>
          <div class="vx-col md:w-full w-full mt-0">
            <vs-input class="w-full mt-0" label="Host (smtp.dominio.com.br)" v-model="mailConfiguration.mail_host" data-vv-scope="mailConfiguration" data-vv-as="Host" v-validate.initial="'required'" name="mail_host" />
            <span class="text-danger text-sm" v-show="errors.has('mailConfiguration.mail_host')">{{ errors.first('mailConfiguration.mail_host') }}</span>
          </div>
          <div class="vx-col md:w-full w-full mt-0">
            <vs-input type="number" class="w-full mt-0" label="Porta (465/587)" v-model="mailConfiguration.mail_port" data-vv-scope="mailConfiguration" data-vv-as="Porta" v-validate.initial="'required'" name="mail_port" />
            <span class="text-danger text-sm" v-show="errors.has('mailConfiguration.mail_port')">{{ errors.first('mailConfiguration.mail_port') }}</span>
          </div>
          <div class="vx-col md:w-full w-full mt-0">
            <vs-input class="w-full mt-0" label="Email de envio (seu@email.com.br)" v-model="mailConfiguration.mail_from_address" data-vv-scope="mailConfiguration" data-vv-as="Email de envio" v-validate.initial="'required|email'" name="mail_from_address" />
            <span class="text-danger text-sm" v-show="errors.has('mailConfiguration.mail_from_address')">{{ errors.first('mailConfiguration.mail_from_address') }}</span>
          </div>
          <div class="vx-col md:w-full w-full mt-0">
            <vs-input class="w-full mt-0" label="Nome (nome para envio)" v-model="mailConfiguration.mail_from_name" data-vv-scope="mailConfiguration" data-vv-as="Nome" v-validate.initial="'required'" name="mail_from_name" />
            <span class="text-danger text-sm" v-show="errors.has('mailConfiguration.mail_from_name')">{{ errors.first('mailConfiguration.mail_from_name') }}</span>
          </div>
          <div class="vx-col md:w-full w-full mt-0">
            <vs-input class="w-full mt-0" label="Criptografia (ssl/tls)" v-model="mailConfiguration.mail_encryption" data-vv-scope="mailConfiguration" data-vv-as="Criptografia" v-validate.initial="'required'" name="mail_encryption" />
            <span class="text-danger text-sm" v-show="errors.has('mailConfiguration.mail_encryption')">{{ errors.first('mailConfiguration.mail_encryption') }}</span>
          </div>
          <div class="vx-col md:w-full w-full mt-0">
            <vs-input class="w-full mt-0" label="Username (seu@email.com.br)" v-model="mailConfiguration.mail_username" data-vv-scope="mailConfiguration" data-vv-as="Username" v-validate.initial="'required'" name="mail_username" />
            <span class="text-danger text-sm" v-show="errors.has('mailConfiguration.mail_username')">{{ errors.first('mailConfiguration.mail_username') }}</span>
          </div>
          <div class="vx-col md:w-full w-full mt-0">
            <vs-input type="password" class="w-full mt-0" label="Password (sua senha)" v-model="mailConfiguration.mail_password" data-vv-scope="mailConfiguration" data-vv-as="Password" v-validate.initial="'required'" name="mail_password" />
            <span class="text-danger text-sm" v-show="errors.has('mailConfiguration.mail_password')">{{ errors.first('mailConfiguration.mail_password') }}</span>
          </div>

          <vs-divider></vs-divider>

          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="mt-4 flex flex-wrap items-center justify-end">
                <vs-button color="danger" class="ml-4" @click="confirmDelete">Deletar Configuração</vs-button>
                <vs-button class="ml-4" @click="save" :disabled="!validateMailConfiguration">Salvar Configuração</vs-button>
              </div>
            </div>
          </div>

        </div>
        <div class="vx-col md:w-1/2 w-full mt-0">
          <vs-alert class="mt-2" color="primary" title="Orientações:" :active.sync="activeAlert" closable icon-pack="feather" close-icon="icon-x">
            <p>Se você utilizar uma conta do Gmail ou kinghost deve seguir os tutoriais descritos abaixo antes de enviar malas diretas pelo SIGCFC.</p>
            <p>Se você utilizar uma conta do Gmail clique <a href="https://sigcfc.com.br/como-configurar-o-envio-de-emails-por-contas-gmail-no-sigcfc/" target="_blank">aqui</a> para acessar ao tutorial.</p>
            <p>Se você utilizar uma conta da kinghost clique <a href="https://sigcfc.com.br/como-configurar-o-envio-de-emails-por-contas-da-kinghost/" target="_blank">aqui</a> para acessar ao tutorial.</p>
          </vs-alert>

          <h3 class="mt-2">Teste de configuração de email</h3>
          <div class="vx-col md:w-full w-full mt-0">
            <vs-input class="w-full mt-0" label="Enviar teste para" v-model="testConfiguration.email" data-vv-scope="testConfiguration" data-vv-as="Email" v-validate.initial="'required|email'" name="email" />
            <span class="text-danger text-sm" v-show="errors.has('testConfiguration.email')">{{ errors.first('testConfiguration.email') }}</span>
          </div>
          <div class="vx-col md:w-full w-full mt-0">
            <vs-input class="w-full mt-0" label="Assunto" v-model="testConfiguration.subject" data-vv-scope="testConfiguration" data-vv-as="Assunto" v-validate.initial="'required'" name="subject" />
            <span class="text-danger text-sm" v-show="errors.has('testConfiguration.subject')">{{ errors.first('testConfiguration.subject') }}</span>
          </div>
          <div class="vx-col md:w-full w-full mt-0">
            <vs-input class="w-full mt-0" label="Mensagem" v-model="testConfiguration.message" data-vv-scope="testConfiguration" data-vv-as="Mensagem" v-validate.initial="'required'" name="message" />
            <span class="text-danger text-sm" v-show="errors.has('testConfiguration.message')">{{ errors.first('testConfiguration.message') }}</span>
          </div>

          <vs-divider></vs-divider>

          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="mt-4 flex flex-wrap items-center justify-end">
                <vs-button class="ml-4" @click="sendTestMail" :disabled="!validateTestConfiguration">Enviar email de teste</vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      activeAlert: true,
      mailConfiguration: {
        mail_host: null,
        mail_port: null,
        mail_from_address: null,
        mail_from_name: null,
        mail_encryption: null,
        mail_username: null,
        mail_password: null
      },
      testConfiguration: {
        email: null,
        subject: 'Teste de configuração de email',
        message: 'Este é apenas um teste de configuração de email.'
      }
    }
  },
  computed: {
    validateMailConfiguration () {
      return !this.errors.any('mailConfiguration')
    },
    validateTestConfiguration () {
      return !this.errors.any('testConfiguration')
    }
  },
  methods: {
    async save () {
      try {
        this.$vs.loading()
        await this.$http.put(`${process.env.VUE_APP_API_URL}/email/config/update`, this.mailConfiguration)
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Alterados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    confirmDelete () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: 'Tem certeza que deseja excluir a configuração de emails?',
        accept: this.deleteMailConfiguration,
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    async deleteMailConfiguration () {
      try {
        this.$vs.loading()
        await this.$http.delete(`${process.env.VUE_APP_API_URL}/email/config`)
        this.$vs.loading.close()

        this.mailConfiguration = {
          mail_host: null,
          mail_port: null,
          mail_from_address: null,
          mail_from_name: null,
          mail_encryption: null,
          mail_username: null,
          mail_password: null
        }

        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Excluído.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    async sendTestMail () {
      try {
        this.$vs.loading()
        await this.$http.post(`${process.env.VUE_APP_API_URL}/email/config/sendmailTest`, this.testConfiguration)
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Email Enviado.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    }
  },
  async created () {
    const configMail = await this.$http.get(`${process.env.VUE_APP_API_URL}/email/config`)
    this.mailConfiguration = configMail.data
  }
}
</script>

<style>
  .vs-tooltip {
    z-index: 99999;
  }
</style>
