var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "configurations-general-visual-tab" } },
    [
      _c(
        "vs-table",
        {
          staticClass: "bordered",
          staticStyle: { overflow: "-webkit-paged-y" },
          attrs: { noDataText: "", data: [], hoverFlat: "" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ data }) {
                return [
                  _c(
                    "vs-tr",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        opacity: "1 !important",
                      },
                    },
                    [
                      _c("vs-td", { staticStyle: { "text-align": "left" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [_vm._v("Mostrar feriados nacionais")]
                        ),
                      ]),
                      _c(
                        "vs-td",
                        { staticClass: "flex flex-wrap items-center" },
                        [
                          _c("vs-checkbox", {
                            staticClass: "vs-checkbox-small ml-0 mr-1 mt-1",
                            model: {
                              value: _vm.configs.show_holidays,
                              callback: function ($$v) {
                                _vm.$set(_vm.configs, "show_holidays", $$v)
                              },
                              expression: "configs.show_holidays",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vs-tr",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        opacity: "1 !important",
                      },
                    },
                    [
                      _c("vs-td", { staticStyle: { "text-align": "left" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [
                            _vm._v(
                              "Utilizar somente maiúsculas nos formulários"
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "vs-td",
                        { staticClass: "flex flex-wrap items-center" },
                        [
                          _c("vs-checkbox", {
                            staticClass: "vs-checkbox-small ml-0 mr-1 mt-1",
                            model: {
                              value: _vm.configs.general.uppercase_forms,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.configs.general,
                                  "uppercase_forms",
                                  $$v
                                )
                              },
                              expression: "configs.general.uppercase_forms",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                _c("span", [_vm._v("Configuração")]),
              ]),
              _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                _c("span", [_vm._v("Ação")]),
              ]),
            ],
            1
          ),
        ],
        2
      ),
      _c("vs-divider"),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [_c("vs-button", { on: { click: _vm.save } }, [_vm._v("Salvar")])],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }