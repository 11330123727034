var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "configurations-schedule-tab" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: {
            fullscreen: false,
            title: "Boleto(s)",
            active: _vm.popupShowBoleto,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowBoleto = $event
            },
          },
        },
        [
          _c("object", {
            staticStyle: { height: "600px" },
            attrs: { data: _vm.srcBoleto, width: "100%" },
          }),
        ]
      ),
      _vm._m(0),
      _c(
        "vs-table",
        {
          staticStyle: { overflow: "-webkit-paged-y" },
          attrs: {
            noDataText: "Nenhuma Mensalidade em Aberto",
            data: _vm.transactions,
            hoverFlat: "",
            stripe: "",
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ data }) {
                return _vm._l(data, function (transaction, i) {
                  return _c(
                    "vs-tr",
                    {
                      key: i,
                      class: [
                        { "text-danger": transaction.situation === "EXPIRED" },
                      ],
                      staticStyle: { opacity: "1 !important" },
                    },
                    [
                      _c("vs-td", [
                        _c(
                          "div",
                          { staticClass: "flex items-center" },
                          [
                            transaction.situation === "EXPIRED"
                              ? _c("feather-icon", {
                                  staticClass: "m-1 w-5 h-5",
                                  attrs: { icon: "AlertCircleIcon" },
                                })
                              : _vm._e(),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  transaction.situation === "EXPIRED"
                                    ? "VENCIDO"
                                    : "À VENCER"
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _c("vs-td", [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateTime")(
                                transaction.expiration_date,
                                "date"
                              )
                            )
                          ),
                        ]),
                      ]),
                      _c(
                        "vs-td",
                        [
                          _c(
                            "vx-tooltip",
                            {
                              attrs: {
                                text: "Imprimir Boleto",
                                position: "left",
                              },
                            },
                            [
                              _c("feather-icon", {
                                attrs: {
                                  icon: "PrinterIcon",
                                  svgClasses:
                                    "h-5 w-5 hover:text-danger cursor-pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.print(transaction.boleto)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                })
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [_vm._v("Situação")]),
              _c("vs-th", [_vm._v("Vencimento")]),
              _c("vs-th", [_vm._v("Boleto")]),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex justify-between flex items-center",
      },
      [
        _c("h5", { staticClass: "mb-2" }, [
          _vm._v("Boletos de Mensalidade Não Pagos"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }