<template>
  <div id="configuration-automatic-email-financial-tab">
        <vs-tabs class="tab-action-btn-fill-conatiner">
          <vs-tab label="Contas à vencer" icon-pack="feather" icon="icon-dollar-sign">
            <div class="tab-text">
              <accounts-due class="mt-4" :mailConfig="mailConfig" :automaticEmails="automaticEmails" />
            </div>
          </vs-tab>
          <vs-tab label="Contas vencidas" icon-pack="feather" icon="icon-calendar">
            <div class="tab-text">
              <overdue-accounts class="mt-4" :mailConfig="mailConfig" :automaticEmails="automaticEmails" />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
</template>

<script>
import AccountsDue      from './tabs/AccountsDue/AccountsDue.vue'
import OverdueAccounts  from './tabs/OverdueAccounts/OverdueAccounts.vue'

export default {
  components: {
    AccountsDue,
    OverdueAccounts
  },
  props: {
    mailConfig: {
      type: [Object, Boolean],
      default: false
    },
    automaticEmails: { type: Array, required: true }
  }
}
</script>

<style lang="scss">
  #configuration-automatic-email-financial-tab {
    .vs-tabs--content {
      padding: 0 10px 23px !important;
    }
  }
  .vs-tooltip {
    z-index: 99999;
  }
</style>
