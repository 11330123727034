var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "page-services" } },
    [
      _c(
        "dialog-sig",
        {
          staticStyle: { "z-index": "999999999999999" },
          attrs: {
            show: _vm.popupDeleteServiceItemsConfirm,
            title: "ALERTA",
            icon: "warning",
          },
          on: {
            action: function ($event) {
              return _vm.removeItem(_vm.i)
            },
            cancel: function ($event) {
              _vm.popupDeleteServiceItemsConfirm = false
            },
          },
        },
        [
          _vm._v(
            "\n      Tem certeza que deseja excluir este ítem de serviço? "
          ),
          _c("br"),
          _vm._v("\n      Esta ação é irreversível!\n  "),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup80",
          attrs: { title: "Ítens de Serviço", active: _vm.popupServiceItems },
          on: {
            "update:active": function ($event) {
              _vm.popupServiceItems = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "vx-row" },
            [
              _c(
                "div",
                { staticClass: "vx-col md:w-1/3 w-full mt-0" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.initial",
                        value: "required",
                        expression: "'required'",
                        modifiers: { initial: true },
                      },
                    ],
                    staticClass: "w-full",
                    attrs: {
                      label: "Ítem",
                      "data-vv-as": "Ítem",
                      "data-vv-scope": "item",
                      name: "name",
                    },
                    model: {
                      value: _vm.serviceItem.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.serviceItem, "name", $$v)
                      },
                      expression: "serviceItem.name",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("item.name"),
                          expression: "errors.has('item.name')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v("Campo Obrigatório.")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/6 w-full mt-0" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v("Tipo"),
                  ]),
                  _c("v-select", {
                    staticClass: "vue_select_drop_size_150",
                    attrs: {
                      reduce: (option) => option.value,
                      clearable: false,
                      options: [
                        { label: "Ítem", value: "ITEM" },
                        { label: "Taxa", value: "TAX" },
                      ],
                      placeholder: "Selecione",
                      dir: _vm.$vs.rtl ? "rtl" : "ltr",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "no-options",
                        fn: function ({}) {
                          return [
                            _vm._v(
                              "\n            Nenhum resultado encontrado.\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.serviceItem.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.serviceItem, "type", $$v)
                      },
                      expression: "serviceItem.type",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/6 w-full mt-0" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v("Valor Un."),
                  ]),
                  _c("currency-input", {
                    staticClass:
                      "w-full vs-inputx vs-input--input normal hasValue",
                    attrs: {
                      currency: { prefix: "R$ " },
                      valueAsInteger: false,
                      distractionFree: false,
                      precision: 2,
                      autoDecimalMode: true,
                      valueRange: { min: 0 },
                      allowNegative: false,
                    },
                    model: {
                      value: _vm.serviceItem.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.serviceItem, "value", $$v)
                      },
                      expression: "serviceItem.value",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/3 w-full mt-4" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass:
                        "shadow-drop sm:mr-4 mr-0 sm:w-auto ml-auto w-full sm:order-normal order-3 sm:mt-0 mt-4",
                      attrs: {
                        disabled: _vm.errors.has("item.name"),
                        "icon-pack": "feather",
                        icon: "icon-plus-circle",
                      },
                      on: { click: _vm.addItem },
                    },
                    [_vm._v("Novo Ítem de Serviço")]
                  ),
                ],
                1
              ),
              _c("vs-divider"),
            ],
            1
          ),
          _c(
            "ul",
            { staticClass: "ml-4" },
            _vm._l(_vm.services, function (item, i) {
              return _c(
                "li",
                {
                  key: i,
                  staticClass: "flex items-center cursor-pointer py-1",
                },
                [
                  !item.edit
                    ? _c(
                        "div",
                        { key: `if1${i}`, staticClass: "vx-row w-full" },
                        [
                          _c(
                            "div",
                            { staticClass: "vx-col md:w-1/3 w-full mt-2" },
                            [
                              _c("feather-icon", {
                                attrs: {
                                  icon: "CircleIcon",
                                  svgClasses: [
                                    { "text-primary fill-current": true },
                                    "h-5 w-5",
                                  ],
                                },
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "ml-2",
                                  class: { "text-primary": true },
                                },
                                [_vm._v(" " + _vm._s(item.service) + " ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "vx-col md:w-1/3 w-full mt-2" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "ml-2",
                                  class: { "text-primary": true },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        item.type === "TAX"
                                          ? "(Taxa)"
                                          : "(Ítem)"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "vx-col md:w-1/3 w-full mt-2" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "ml-2",
                                  class: { "text-primary": true },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        item.value
                                          ? item.value.toLocaleString("pt-BR", {
                                              style: "currency",
                                              currency: "BRL",
                                            })
                                          : "R$ 0,00"
                                      ) +
                                      "\n              "
                                  ),
                                  _c("feather-icon", {
                                    staticClass: "ml-4",
                                    attrs: {
                                      icon: "Edit3Icon",
                                      svgClasses: "w-4 h-4",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.itemEdit(i)
                                      },
                                    },
                                  }),
                                  _c("feather-icon", {
                                    staticClass: "ml-4",
                                    attrs: {
                                      icon: "Trash2Icon",
                                      svgClasses: "w-4 h-4",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.confirmDeleteItem(i)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      )
                    : _c("div", { staticClass: "vx-row w-full" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/3 w-full mt-0" },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: { label: "Ítem" },
                              model: {
                                value: item.service,
                                callback: function ($$v) {
                                  _vm.$set(item, "service", $$v)
                                },
                                expression: "item.service",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                          [
                            _c("label", { staticClass: "vs-input--label" }, [
                              _vm._v("Tipo"),
                            ]),
                            _c("v-select", {
                              staticClass: "vue_select_drop_size_150",
                              attrs: {
                                reduce: (option) => option.value,
                                clearable: false,
                                options: [
                                  { label: "Ítem", value: "ITEM" },
                                  { label: "Taxa", value: "TAX" },
                                ],
                                placeholder: "Selecione",
                                dir: _vm.$vs.rtl ? "rtl" : "ltr",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "no-options",
                                    fn: function ({}) {
                                      return [
                                        _vm._v(
                                          "\n                Nenhum resultado encontrado.\n              "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: item.type,
                                callback: function ($$v) {
                                  _vm.$set(item, "type", $$v)
                                },
                                expression: "item.type",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                          [
                            _c("label", { staticClass: "vs-input--label" }, [
                              _vm._v("Valor Un."),
                            ]),
                            _c("currency-input", {
                              staticClass:
                                "w-full vs-inputx vs-input--input normal hasValue",
                              attrs: {
                                currency: { prefix: "R$ " },
                                valueAsInteger: false,
                                distractionFree: false,
                                precision: 2,
                                autoDecimalMode: true,
                                valueRange: { min: 0 },
                                allowNegative: false,
                              },
                              model: {
                                value: item.value,
                                callback: function ($$v) {
                                  _vm.$set(item, "value", $$v)
                                },
                                expression: "item.value",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/6 w-full mt-8" },
                          [
                            _c("feather-icon", {
                              staticClass: "cursor-pointer ml-4",
                              class: { "text-primary": true },
                              attrs: {
                                icon: "CheckCircleIcon",
                                svgClasses: "w-6 h-6",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.updateItem(i)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                ]
              )
            }),
            0
          ),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupServiceItems = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup80",
          attrs: { title: "Cadastro de Serviço", active: _vm.popupAddService },
          on: {
            "update:active": function ($event) {
              _vm.popupAddService = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    label: "Serviço",
                    "data-vv-as": "Serviço",
                    "data-vv-scope": "service",
                    name: "name",
                  },
                  model: {
                    value: _vm.service.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.service, "name", $$v)
                    },
                    expression: "service.name",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("service.name"),
                        expression: "errors.has('service.name')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Campo Obrigatório.")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Categoria"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_150",
                  attrs: {
                    reduce: (option) => option.value,
                    options: _vm.cnhOptions,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.service.category_cnh,
                    callback: function ($$v) {
                      _vm.$set(_vm.service, "category_cnh", $$v)
                    },
                    expression: "service.category_cnh",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Plano de contas"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "vue_select_drop_size_150",
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    "data-vv-as": "Plano de Contas",
                    "data-vv-scope": "service",
                    name: "accountPlan",
                    options: _vm.accountPlans,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.service.account_plan_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.service, "account_plan_id", $$v)
                    },
                    expression: "service.account_plan_id",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("service.accountPlan"),
                        expression: "errors.has('service.accountPlan')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Campo Obrigatório.")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Tipo de Serviço"),
                ]),
                _c("v-select", {
                  staticStyle: { "background-color": "white" },
                  attrs: {
                    clearable: true,
                    reduce: (option) => option.value,
                    options: _vm.typeServiceOptions,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.service.type_service,
                    callback: function ($$v) {
                      _vm.$set(_vm.service, "type_service", $$v)
                    },
                    expression: "service.type_service",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/3 w-full mt-0" }, [
              _c("label", { staticClass: "vs-input--label" }, [
                _c("strong", [_vm._v("Valor Taxas")]),
              ]),
              _c("br"),
              _c("h5", { staticClass: "mt-2" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.service.totalTax
                        ? _vm.service.totalTax.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })
                        : "R$ 0,00"
                    ) +
                    "\n        "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _c("strong", [_vm._v("Valor Total")]),
                ]),
                _c("currency-input", {
                  staticClass:
                    "w-full vs-inputx vs-input--input normal hasValue",
                  attrs: {
                    currency: { prefix: "R$ " },
                    valueAsInteger: false,
                    distractionFree: false,
                    precision: 2,
                    autoDecimalMode: true,
                    valueRange: { min: 0 },
                    allowNegative: false,
                  },
                  model: {
                    value: _vm.service.value,
                    callback: function ($$v) {
                      _vm.$set(_vm.service, "value", $$v)
                    },
                    expression: "service.value",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "vx-row flex flex-wrap items-center justify-center",
            },
            [
              _c(
                "div",
                { staticClass: "vx-col md:w-4/5 w-full mt-2" },
                [
                  _c(
                    "vs-divider",
                    {
                      staticClass: "d-theme-border-grey-light m-0",
                      attrs: { position: "left" },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "MenuIcon", svgClasses: "w-4 h-4" },
                      }),
                      _vm._v("Ítens do Serviço\n        "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "vx-col md:w-1/5 w-full mt-3 flex flex-wrap justify-center",
                },
                [
                  _c("vs-button", {
                    attrs: {
                      radius: "",
                      color: "primary",
                      type: "border",
                      "icon-pack": "feather",
                      icon: "icon-plus-circle",
                    },
                    on: { click: _vm.addServiceItem },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._l(_vm.service.items, function (item, i) {
            return _c("div", { key: i, staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col md:w-1/6 w-full mt-0" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { type: "number", min: "1", label: "Qtd." },
                    on: { change: _vm.calculateTotalValues },
                    model: {
                      value: item.quantity,
                      callback: function ($$v) {
                        _vm.$set(item, "quantity", $$v)
                      },
                      expression: "item.quantity",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/3 w-full mt-0" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v("Ítem/Taxa"),
                  ]),
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.initial",
                        value: { required: !item.id },
                        expression: "{ required: !item.id }",
                        modifiers: { initial: true },
                      },
                    ],
                    staticClass: "vue_select_drop_size_98",
                    attrs: {
                      reduce: (option) => option.value,
                      clearable: false,
                      options: _vm.serviceOptions,
                      placeholder: "Selecione",
                      dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      "data-vv-as": "Ítem|Taxa",
                      "data-vv-scope": "service",
                      name: "id" + i,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.changeItem(i)
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n            Nenhum resultado encontrado.\n          "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: item.id,
                      callback: function ($$v) {
                        _vm.$set(item, "id", $$v)
                      },
                      expression: "item.id",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("service.id" + i),
                          expression: "errors.has('service.id'+i)",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v("Campo obrigatório")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/6 w-full mt-0" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v("Valor Un."),
                  ]),
                  _c("currency-input", {
                    staticClass:
                      "w-full vs-inputx vs-input--input normal hasValue",
                    attrs: {
                      currency: { prefix: "R$ " },
                      valueAsInteger: false,
                      distractionFree: false,
                      precision: 2,
                      autoDecimalMode: true,
                      valueRange: { min: 0 },
                      allowNegative: false,
                    },
                    on: { change: _vm.calculateTotalValues },
                    model: {
                      value: item.value,
                      callback: function ($$v) {
                        _vm.$set(item, "value", $$v)
                      },
                      expression: "item.value",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/6 w-full mt-0" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v("Total"),
                  ]),
                  _c("br"),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        item.total
                          ? item.total.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })
                          : "R$ 0,00"
                      ) +
                      "\n        "
                  ),
                  _c("feather-icon", {
                    staticClass: "ml-4 cursor-pointer",
                    attrs: { icon: "Trash2Icon", svgClasses: "w-4 h-4" },
                    on: {
                      click: function ($event) {
                        return _vm.removeServiceItem(i)
                      },
                    },
                  }),
                ],
                1
              ),
            ])
          }),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupAddService = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      attrs: {
                        disabled:
                          !_vm.validateServiceForm || _vm.disableServiceSave,
                      },
                      on: { click: _vm.addService },
                    },
                    [_vm._v("Salvar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "vx-card p-6" },
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center" },
            [
              _c(
                "div",
                { staticClass: "flex-grow" },
                [
                  _c(
                    "vs-dropdown",
                    {
                      staticClass: "cursor-pointer",
                      attrs: { "vs-trigger-click": "" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium",
                        },
                        [
                          _c("span", { staticClass: "mr-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.currentPage * _vm.paginationPageSize -
                                  (_vm.paginationPageSize - 1)
                              ) +
                                " - " +
                                _vm._s(
                                  _vm.allData.total -
                                    _vm.currentPage * _vm.paginationPageSize >
                                    0
                                    ? _vm.currentPage * _vm.paginationPageSize
                                    : _vm.allData.total
                                ) +
                                " de " +
                                _vm._s(_vm.allData.total)
                            ),
                          ]),
                          _c("feather-icon", {
                            attrs: {
                              icon: "ChevronDownIcon",
                              svgClasses: "h-4 w-4",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-dropdown-menu",
                        [
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.paginationSetPageSize(10)
                                },
                              },
                            },
                            [_c("span", [_vm._v("10")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.paginationSetPageSize(20)
                                },
                              },
                            },
                            [_c("span", [_vm._v("20")])]
                          ),
                          _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.paginationSetPageSize(30)
                                },
                              },
                            },
                            [_c("span", [_vm._v("30")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "vs-button",
                {
                  staticClass:
                    "shadow-drop sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4",
                  attrs: { "icon-pack": "feather", icon: "icon-plus-circle" },
                  on: { click: _vm.addServiceHandle },
                },
                [_vm._v("Novo Serviço")]
              ),
              _c(
                "vs-button",
                {
                  staticClass:
                    "shadow-drop sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4",
                  attrs: {
                    type: "border",
                    "icon-pack": "feather",
                    icon: "icon-menu",
                  },
                  on: { click: _vm.serviceItemsHandle },
                },
                [_vm._v("Ítens de Serviço")]
              ),
            ],
            1
          ),
          _c("ag-grid-vue", {
            ref: "agGridTable",
            staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
            attrs: {
              components: _vm.components,
              gridOptions: _vm.gridOptions,
              columnDefs: _vm.columnDefs,
              defaultColDef: _vm.defaultColDef,
              overlayLoadingTemplate: _vm.overlayLoadingTemplate,
              overlayNoRowsTemplate: _vm.noRowsTemplate,
              localeText: _vm.localeText,
              rowSelection: "multiple",
              colResizeDefault: "shift",
              animateRows: true,
              pagination: true,
              paginationPageSize: _vm.paginationPageSize,
              cacheBlockSize: 40,
              suppressPaginationPanel: true,
              enableRtl: _vm.$vs.rtl,
            },
            on: { "grid-ready": _vm.onGridReady },
          }),
          _c("vs-pagination", {
            attrs: { total: _vm.totalPages, max: 10 },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }