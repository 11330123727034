var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "configurations-general-financial-tab" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup50",
          attrs: {
            title: "Alterar PróximoNúmero de Recibo",
            active: _vm.popupChangeReceiptNextNumber,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupChangeReceiptNextNumber = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:wfull w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required|min_value:1",
                      expression: "'required|min_value:1'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    type: "number",
                    label: "Próximo Número",
                    "data-vv-scope": "changeReceiptNextNumber",
                    "data-vv-as": "Próximo Número",
                    name: "next_number",
                  },
                  model: {
                    value: _vm.receipt.next_number,
                    callback: function ($$v) {
                      _vm.$set(_vm.receipt, "next_number", $$v)
                    },
                    expression: "receipt.next_number",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has(
                          "changeReceiptNextNumber.next_number"
                        ),
                        expression:
                          "errors.has('changeReceiptNextNumber.next_number')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.errors.first("changeReceiptNextNumber.next_number")
                      )
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupChangeReceiptNextNumber = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      attrs: {
                        disabled: !_vm.validateChangeReceiptNextNumberForm,
                      },
                      on: { click: _vm.changeReceiptNextNumber },
                    },
                    [_vm._v("Salvar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "vs-table",
        {
          staticClass: "bordered",
          staticStyle: { overflow: "-webkit-paged-y" },
          attrs: { noDataText: "", data: [], hoverFlat: "" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ data }) {
                return [
                  _c(
                    "vs-tr",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        opacity: "1 !important",
                      },
                    },
                    [
                      _c("vs-td", { staticStyle: { "text-align": "left" } }, [
                        _c(
                          "div",
                          { staticClass: "flex flex-wrap items-center" },
                          [
                            _c(
                              "span",
                              { staticClass: "cursor-default font-semibold" },
                              [_vm._v("Póximo Número de Recibo")]
                            ),
                            _c(
                              "vx-tooltip",
                              {
                                staticClass: "ml-4",
                                attrs: {
                                  text: "Alterar Próximo Número de Recibo",
                                  position: "top",
                                },
                              },
                              [
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "EditIcon",
                                    svgClasses:
                                      "h-5 w-5 cursor-pointer hover:text-primary",
                                  },
                                  on: {
                                    click: _vm.handleChangeReceiptNextNumber,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "vs-td",
                        { staticClass: "flex flex-wrap items-center" },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.companyConfigurations
                                    .receipt_number_control.last_number + 1
                                )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vs-tr",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        opacity: "1 !important",
                      },
                    },
                    [
                      _c("vs-td", { staticStyle: { "text-align": "left" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [_vm._v("Vias de Recibo")]
                        ),
                      ]),
                      _c(
                        "vs-td",
                        { staticClass: "flex flex-wrap items-center" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.initial",
                                value: "required|min_value:1",
                                expression: "'required|min_value:1'",
                                modifiers: { initial: true },
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              type: "number",
                              label: "",
                              "data-vv-as": "Vias de Recibo",
                              name: "receipt_vias",
                            },
                            model: {
                              value: _vm.configs.documents.receipt_vias,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.configs.documents,
                                  "receipt_vias",
                                  $$v
                                )
                              },
                              expression: "configs.documents.receipt_vias",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("receipt_vias"),
                                  expression: "errors.has('receipt_vias')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v(_vm._s(_vm.errors.first("receipt_vias")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vs-tr",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        opacity: "1 !important",
                      },
                    },
                    [
                      _c("vs-td", { staticStyle: { "text-align": "left" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [_vm._v("Ocultar Observações no Recibo")]
                        ),
                      ]),
                      _c(
                        "vs-td",
                        { staticClass: "flex flex-wrap items-center" },
                        [
                          _c("vs-checkbox", {
                            staticClass: "vs-checkbox-small ml-0 mr-1 mt-1",
                            model: {
                              value: _vm.configs.documents.receipt_observations,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.configs.documents,
                                  "receipt_observations",
                                  $$v
                                )
                              },
                              expression:
                                "configs.documents.receipt_observations",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                _c("span", [_vm._v("Configuração")]),
              ]),
              _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                _c("span", [_vm._v("Ação")]),
              ]),
            ],
            1
          ),
        ],
        2
      ),
      _c("vs-divider"),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "vs-button",
                {
                  attrs: { disabled: !_vm.validateForm },
                  on: { click: _vm.save },
                },
                [_vm._v("Salvar")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }