import axios from '@/axios.js'

export default {
  fetchAll ({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      if (!state.contractModels.length) {
        axios.get(`${process.env.VUE_APP_API_URL}/contractModels`, payload)
          .then((response) => {
            commit('REFRESH', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      } else {
        resolve(state.contractModels)
      }
    })
  },
  store ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/contract/model`, payload)
        .then((resp) => {
          commit('STORE', resp.data)
          resolve(resp)
        })
        .catch((error) => { reject(error) })
    })
  },
  update ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/contractModel/${payload.id}`, payload)
        .then((resp) => {
          commit('UPDATE', resp.data)
          resolve(resp)
        })
        .catch((error) => { reject(error) })
    })
  },
  delete ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_API_URL}/contractModel/${id}`)
        .then((response) => {
          commit('DELETE', id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchGrid ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/contractModels`, payload)
        .then((response) => {
          commit('SET_CONTRACTS_GRID', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
