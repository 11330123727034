var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "configuration-cfc-data-tab" } },
    [
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c("div", { staticClass: "flex items-start flex-col lg:flex-row" }, [
            _c("input", {
              attrs: { id: "fileUpload", type: "file", hidden: "" },
              on: { change: _vm.onFileSelected },
            }),
            _c(
              "div",
              {
                staticClass:
                  "cursor-pointer p-2 vs-con-loading__container mt-6",
                staticStyle: {
                  position: "absolute",
                  width: "200px",
                  height: "200px",
                },
                attrs: { id: "logo-loading" },
                on: {
                  mouseover: function ($event) {
                    _vm.mouseOver = true
                  },
                  mouseleave: function ($event) {
                    _vm.mouseOver = false
                  },
                  click: _vm.chooseFile,
                },
              },
              [
                _vm.mouseOver
                  ? _c("feather-icon", {
                      staticStyle: {
                        "margin-left": "55px",
                        "margin-top": "54px",
                      },
                      attrs: {
                        svgClasses: "h-16 w-16 stroke-current text-success",
                        icon: "CameraIcon",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c("img", {
              staticClass: "mt-6 mr-8 rounded h-56 w-56",
              attrs: {
                src:
                  _vm.company.logo && _vm.company.logo.url
                    ? _vm.company.logo.url
                    : _vm.noImage,
              },
              on: { load: _vm.closeLoading },
            }),
            _c("div", { staticClass: "w-full" }, [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      staticClass: "w-full mt-0",
                      attrs: {
                        label: "Razão Social",
                        "data-vv-as": "Razão Social",
                        name: "corporate_name",
                      },
                      model: {
                        value: _vm.company.corporate_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.company, "corporate_name", $$v)
                        },
                        expression: "company.corporate_name",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("corporate_name"),
                            expression: "errors.has('corporate_name')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("corporate_name")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      staticClass: "w-full mt-0",
                      attrs: {
                        label: "Nome Fantasia",
                        "data-vv-as": "Nome Fantasia",
                        name: "trading_name",
                      },
                      model: {
                        value: _vm.company.trading_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.company, "trading_name", $$v)
                        },
                        expression: "company.trading_name",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("trading_name"),
                            expression: "errors.has('trading_name')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("trading_name")))]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full mt-2" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["###.###.###-##", "##.###.###/####-##"],
                          expression: "['###.###.###-##','##.###.###/####-##']",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "cpf",
                          expression: "'cpf'",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: {
                        label: "CNPJ",
                        "data-vv-as": "CNPJ",
                        name: "cnpj",
                      },
                      model: {
                        value: _vm.company.cnpj,
                        callback: function ($$v) {
                          _vm.$set(_vm.company, "cnpj", $$v)
                        },
                        expression: "company.cnpj",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("cnpj"),
                            expression: "errors.has('cnpj')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("cnpj")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "IE" },
                      model: {
                        value: _vm.company.ie,
                        callback: function ($$v) {
                          _vm.$set(_vm.company, "ie", $$v)
                        },
                        expression: "company.ie",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "Reg. DETRAN" },
                      model: {
                        value: _vm.company.register_detran,
                        callback: function ($$v) {
                          _vm.$set(_vm.company, "register_detran", $$v)
                        },
                        expression: "company.register_detran",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "flex items-end mt-2" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-2",
                    attrs: { icon: "MapPinIcon", svgClasses: "w-5 h-5" },
                  }),
                  _c("span", { staticClass: "leading-none font-medium" }, [
                    _vm._v("Endereço"),
                  ]),
                ],
                1
              ),
              _c("hr"),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["#####-###"],
                          expression: "['#####-###']",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: { label: "CEP", placeholder: "CEP" },
                      on: { keyup: _vm.searchZipCode },
                      model: {
                        value: _vm.company.addresses.zip_code,
                        callback: function ($$v) {
                          _vm.$set(_vm.company.addresses, "zip_code", $$v)
                        },
                        expression: "company.addresses.zip_code",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Logradouro"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        reduce: (option) => option.value,
                        clearable: "",
                        options: _vm.logradouroOptions,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n                    Desculpe, nenhum resultado encontrado.\n                  "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.company.addresses.logradouro,
                        callback: function ($$v) {
                          _vm.$set(_vm.company.addresses, "logradouro", $$v)
                        },
                        expression: "company.addresses.logradouro",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "Endereço" },
                      model: {
                        value: _vm.company.addresses.street,
                        callback: function ($$v) {
                          _vm.$set(_vm.company.addresses, "street", $$v)
                        },
                        expression: "company.addresses.street",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        label: "Número",
                        type: "number",
                        placeholder: "S/N",
                      },
                      model: {
                        value: _vm.company.addresses.number,
                        callback: function ($$v) {
                          _vm.$set(_vm.company.addresses, "number", $$v)
                        },
                        expression: "company.addresses.number",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full" },
          [
            _c("vs-input", {
              staticClass: "w-full mt-2",
              attrs: { label: "Complemento" },
              model: {
                value: _vm.company.addresses.complement,
                callback: function ($$v) {
                  _vm.$set(_vm.company.addresses, "complement", $$v)
                },
                expression: "company.addresses.complement",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full" },
          [
            _c("vs-input", {
              staticClass: "w-full mt-2",
              attrs: { label: "Bairro" },
              model: {
                value: _vm.company.addresses.neighborhood,
                callback: function ($$v) {
                  _vm.$set(_vm.company.addresses, "neighborhood", $$v)
                },
                expression: "company.addresses.neighborhood",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/4_ w-full mt-2" },
          [
            _c("label", { staticClass: "vs-input--label" }, [_vm._v("Cidade")]),
            _c("v-select", {
              staticClass: "vue_select_drop_size_200",
              attrs: {
                reduce: (option) => option.value,
                placeholder: _vm.cityOptions.length
                  ? "Selecione"
                  : "Selecione um estado",
                options: _vm.cityOptions,
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n            Desculpe, nenhum resultado encontrado.\n          "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.company.addresses.city_id,
                callback: function ($$v) {
                  _vm.$set(_vm.company.addresses, "city_id", $$v)
                },
                expression: "company.addresses.city_id",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/6 w-full mt-2" },
          [
            _c("label", { staticClass: "vs-input--label" }, [_vm._v("Estado")]),
            _c("v-select", {
              staticClass: "vue_select_drop_size_200",
              attrs: {
                reduce: (option) => option.value,
                options: _vm.states,
                placeholder: "Selecione",
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              on: {
                input: function ($event) {
                  _vm.fillCity(_vm.company.addresses.state_id),
                    (_vm.company.addresses.city_id = null)
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n            Desculpe, nenhum resultado encontrado.\n          "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.company.addresses.state_id,
                callback: function ($$v) {
                  _vm.$set(_vm.company.addresses, "state_id", $$v)
                },
                expression: "company.addresses.state_id",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/6 w-full mt-2" },
          [
            _c("label", { staticClass: "vs-input--label" }, [_vm._v("País")]),
            _c("v-select", {
              attrs: {
                reduce: (option) => option.value,
                clearable: false,
                options: _vm.countryOptions,
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n            Desculpe, nenhum resultado encontrado.\n          "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.company.addresses.country,
                callback: function ($$v) {
                  _vm.$set(_vm.company.addresses, "country", $$v)
                },
                expression: "company.addresses.country",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "flex items-end mt-2" },
        [
          _c("feather-icon", {
            staticClass: "mr-2",
            attrs: { icon: "PhoneIcon", svgClasses: "w-5 h-5" },
          }),
          _c("span", { staticClass: "leading-none font-medium" }, [
            _vm._v("Contato"),
          ]),
        ],
        1
      ),
      _c("hr"),
      _c(
        "div",
        { staticClass: "vx-row" },
        [
          _vm._l(_vm.company.phones, function (phone, i) {
            return _c(
              "div",
              { key: i, staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: ["(##)#####-####", "(##)####-####"],
                      expression: "['(##)#####-####','(##)####-####']",
                    },
                  ],
                  staticClass: "w-full mt-2",
                  attrs: {
                    label:
                      phone.type === "phone"
                        ? "Telefone"
                        : phone.type === "mobile"
                        ? "Celular"
                        : phone.type === "other"
                        ? "Outro"
                        : "",
                  },
                  model: {
                    value: phone.phone,
                    callback: function ($$v) {
                      _vm.$set(phone, "phone", $$v)
                    },
                    expression: "phone.phone",
                  },
                }),
              ],
              1
            )
          }),
          _c(
            "div",
            { staticClass: "vx-col md:w-2/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "email",
                    expression: "'email'",
                  },
                ],
                staticClass: "w-full mt-2",
                attrs: {
                  label: "E-mail",
                  "data-vv-as": "E-mail",
                  name: "email",
                },
                model: {
                  value: _vm.company.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "email", $$v)
                  },
                  expression: "company.email",
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("email"),
                      expression: "errors.has('email')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first("email")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("vs-divider"),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "div",
            { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "ml-auto mt-2",
                  attrs: { disabled: !_vm.validateForm },
                  on: { click: _vm.save },
                },
                [_vm._v("Salvar Alterações")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }