<template>
  <div id="configurations-general-financial-tab">
    <vs-popup class="popup50" title="Alterar PróximoNúmero de Recibo" :active.sync="popupChangeReceiptNextNumber">

      <div class="vx-row">
        <div class="vx-col md:wfull w-full mt-0">
          <vs-input type="number" v-model="receipt.next_number" class="w-full" label="Próximo Número" data-vv-scope="changeReceiptNextNumber" data-vv-as="Próximo Número" v-validate.initial="'required|min_value:1'" name="next_number" />
          <span class="text-danger text-sm" v-show="errors.has('changeReceiptNextNumber.next_number')">{{ errors.first('changeReceiptNextNumber.next_number') }}</span>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button color="secondary" class="ml-auto" @click="popupChangeReceiptNextNumber = false">Cancelar</vs-button>
              <vs-button class="ml-4" @click="changeReceiptNextNumber" :disabled="!validateChangeReceiptNextNumberForm">Salvar</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>

    <vs-table noDataText="" :data="[]" class="bordered" hoverFlat style="overflow: -webkit-paged-y;">
      <template slot="thead">
        <vs-th style="font-size: 11px;"><span>Configuração</span></vs-th>
        <vs-th style="font-size: 11px;"><span>Ação</span></vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr style="font-size: 12px; opacity: 1 !important">
          <vs-td style="text-align:left">
            <div class="flex flex-wrap items-center">
            <span class="cursor-default font-semibold">Póximo Número de Recibo</span>
            <vx-tooltip text="Alterar Próximo Número de Recibo" position="top" class="ml-4">
              <feather-icon icon="EditIcon" @click="handleChangeReceiptNextNumber" svgClasses="h-5 w-5 cursor-pointer hover:text-primary"></feather-icon>
            </vx-tooltip>
            </div>
          </vs-td>
          <vs-td class="flex flex-wrap items-center">
            <span> {{ companyConfigurations.receipt_number_control.last_number + 1 }}</span>
          </vs-td>
        </vs-tr>
        <vs-tr style="font-size: 12px; opacity: 1 !important">
          <vs-td style="text-align:left">
            <span class="cursor-default font-semibold">Vias de Recibo</span>
          </vs-td>
          <vs-td class="flex flex-wrap items-center">
            <vs-input type="number" v-model="configs.documents.receipt_vias" class="w-full" label="" data-vv-as="Vias de Recibo" v-validate.initial="'required|min_value:1'" name="receipt_vias" />
            <span class="text-danger text-sm" v-show="errors.has('receipt_vias')">{{ errors.first('receipt_vias') }}</span>
          </vs-td>
        </vs-tr>
        <vs-tr style="font-size: 12px; opacity: 1 !important">
          <vs-td style="text-align:left">
            <span class="cursor-default font-semibold">Ocultar Observações no Recibo</span>
          </vs-td>
          <vs-td class="flex flex-wrap items-center">
            <vs-checkbox v-model="configs.documents.receipt_observations" class="vs-checkbox-small ml-0 mr-1 mt-1"></vs-checkbox>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-divider></vs-divider>

    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="flex flex-wrap items-center justify-end">
          <vs-button @click="save" :disabled="!validateForm">Salvar</vs-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  computed: {
    validateForm () {
      return !this.errors.any()
    },
    validateChangeReceiptNextNumberForm () {
      return !this.errors.any('changeReceiptNextNumber')
    },
    user () {
      return this.$store.state.AppActiveUser
    },
    companyConfigurations () {
      return this.$store.getters.companyConfigurations
    }
  },
  data () {
    return {
      popupChangeReceiptNextNumber: false,
      receipt: {
        next_number: 1
      },
      configs: {
        type_configuration: 'BY_COMPANY',
        documents: {
          receipt_vias: 2,
          receipt_observations: false
        }
      }
    }
  },
  methods: {
    handleChangeReceiptNextNumber () {
      if (!this.user.roles.some(role => role.name === 'admin')) {
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Somente usuários administradores podem efetuar esta ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      this.receipt.next_number = JSON.stringify(this.companyConfigurations.receipt_number_control.last_number + 1)
      this.popupChangeReceiptNextNumber = true
    },
    async changeReceiptNextNumber () {
      try {
        this.$vs.loading()

        await this.$http.put(`${process.env.VUE_APP_API_URL}/receiptNumberControl`, this.receipt)

        const company = JSON.parse(JSON.stringify(this.$store.state.AppActiveUser.company))
        const lastNumber = (parseInt(JSON.parse(JSON.stringify(this.receipt.next_number))) - 1)

        //necessário para o primeiro cadastro, quando receipt_number_control não existe em company
        if (!company.receipt_number_control) {
          Object.assign(company, {receipt_number_control: { last_number: lastNumber }})
        } else {
          company.receipt_number_control.last_number = lastNumber
        }
        this.$store.commit('UPDATE_USER_INFO', { company })
        this.popupChangeReceiptNextNumber = false
        this.$nextTick(() => {
          this.$vs.loading.close()
        })

        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Alterados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.log(error)
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    async save () {
      try {
        this.$vs.loading()

        await this.$http.post(`${process.env.VUE_APP_API_URL}/configuration`, this.configs)

        const company = JSON.parse(JSON.stringify(this.$store.state.AppActiveUser.company))
        company.configuration = JSON.parse(JSON.stringify(this.configs))
        this.$store.commit('UPDATE_USER_INFO', { company })

        this.$nextTick(() => {
          this.$vs.loading.close()
        })

        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Alterados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.log(error)
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    }
  },
  mounted () {
    this.configs = JSON.parse(JSON.stringify(this.companyConfigurations))
    this.configs.type_configuration = 'BY_COMPANY'
  }
}
</script>

<style>
  #configurations-general-financial-tab td {
    border-top: 1px solid #c0c0c0;
    line-height: 2;
  }
  #configurations-general-financial-tab .vs-con-table .vs-con-tbody {
    border: 1px solid #c0c0c0;
  }
  .tab-action-btn-fill-conatiner.con-vs-tabs .vs-tabs--content {
    padding: 0px 10px !important;
  }
  .vs-table--not-data {
    padding: 0px;
  }
  .popup50 .vs-popup {
    width: 50% !important;
  }

</style>
