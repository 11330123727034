<template>
  <div id="configuration-general-tab">
    <vs-tabs id="configuration-general-internal-tab" position="left" color="primary">
      <vs-tab label="Financeiro">
        <div class="tab-text">
          <financial class="mt-4" />
        </div>
      </vs-tab>
      <vs-tab label="Sinc. IBio">
        <div class="tab-text">
          <ibio class="mt-4" />
        </div>
      </vs-tab>
      <vs-tab label="Visual">
        <div class="tab-text">
          <visual class="mt-4" />
        </div>
      </vs-tab>
      <vs-tab label="Relatórios">
        <div class="tab-text">
          <report class="mt-4" />
        </div>
      </vs-tab>
      <vs-tab label="Recibos">
        <div class="tab-text">
          <receipt class="mt-4" />
        </div>
      </vs-tab>
      <vs-tab label="Contratos">
        <div class="tab-text">
          <contract class="mt-4" />
        </div>
      </vs-tab>
      <vs-tab label="Alertas">
        <div class="tab-text">
          <alert class="mt-4" />
        </div>
      </vs-tab>
      <vs-tab label="E-mail">
        <div class="tab-text">
          <email class="mt-4" />
        </div>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import formatDateTime from '@/util/formatDateTime.js'

import Financial  from './tabs/Financial/Financial.vue'
import Ibio       from './tabs/Ibio/Ibio.vue'
import Receipt    from './tabs/Receipt/Receipt.vue'
import Report     from './tabs/Report/Report.vue'
import Visual     from './tabs/Visual/Visual.vue'
import Contract   from './tabs/Contract/Contract.vue'
import Alert      from './tabs/Alert/Alert.vue'
import Email      from './tabs/Email/Email.vue'

export default {
  components: {
    Financial,
    Ibio,
    Receipt,
    Report,
    Visual,
    Contract,
    Alert,
    Email
  },

  props: {

  },
  data () {
    return {

    }
  },
  methods: {
    formatDateTime (dateTimeString, type = null) {
      return formatDateTime(dateTimeString, type)
    }
  },
  mounted () {

  },
  created () {

  }
}
</script>

<style>
  #configuration-general-internal-tab .vs-tabs--li button.vs-tabs--btn {
    text-align: end;
  }
  .vs-tooltip {
    z-index: 99999;
  }
</style>
