<template>
  <div id="configurations-general-configIbio-tab">
    <vs-popup :active.sync="listSchedulePopup" title="Aulas pendentes - não sincronizadas" fullscreen>
        <div>
          <h4>As aulas abaixo não foram enviadas para o IBIO, portanto, serão removidas.</h4>
          <h3>Selecione as aulas que deseja manter no sistema.</h3>
           <vs-divider />
           <div class="flex flex-row-reverse">
            <vs-button @click="syncIbioNow">Importar agenda do IBIO</vs-button>
          </div>
           <vs-divider />
          <list-schedule-1 v-model="selectedSchedules" :data="schedules" />
          <vs-divider />
           <div class="flex flex-row-reverse">
            <vs-button @click="syncIbioNow">Importar agenda do IBIO</vs-button>
          </div>
        </div>
    </vs-popup>
    <div class="vx-row mt-4">
      <div class="vx-col md:w-1/2 w-full mt-0">
        <h3 class="mt-2">Alterar dados de login Ibio</h3>
        <div class="vx-col md:w-full w-full mt-0">
          <vs-input
            class="w-full mt-0"
            autocomplete="off"
            label="Username"
            v-model="configIbio.login"
            data-vv-scope="configIbio"
            data-vv-as="Username"
            v-validate.initial="'required'"
            name="login"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('configIbio.login')"
            >{{ errors.first('configIbio.login') }}</span
          >
        </div>
        <div class="vx-col md:w-full w-full mt-0">
          <vs-input
            type="password"
            autocomplete="off"
            class="w-full mt-0"
            label="Password (sua senha)"
            v-model="configIbio.password"
            data-vv-scope="configIbio"
            data-vv-as="Password"
            v-validate.initial="'required'"
            name="password"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('configIbio.password')"
            >{{ errors.first('configIbio.password') }}</span
          >
        </div>

        <vs-divider></vs-divider>

        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="mt-4 flex flex-wrap items-center justify-end">
              <vs-button
                class="mb-4"
                @click="save"
                :disabled="!validateconfigIbio"
                >Salvar Configuração</vs-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="vx-col md:w-1/2 w-full mt-0">
        <h3 class="mt-2">Importação da Agenda</h3>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-input
              type="date"
              class="w-full"
              label="Data Inicial"
              v-model="startDate"
              ref="startDate"
              v-validate="'date_format:yyyy-MM-dd'"
              data-vv-as="Data Inicial"
              name="startDate"
            />
            <span class="text-danger text-sm" v-show="errors.has('startDate')"
              >Data inicial deve ser menor que a final</span
            >
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-input
              type="date"
              class="w-full"
              label="Data Final"
              ref="endDate"
              v-model="endDate"
              data-vv-delay="500"
              v-validate="'date_format:yyyy-MM-dd|dateGreaterOrEqualThan:startDate|maxDate:startDate,15,dias,days'"
              data-vv-as="Data Final"
              name="endDate"
            />
            <span class="text-danger text-sm" v-show="errors.has('endDate')"
              >{{errors.first('endDate')}}</span
            >
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="mt-4 flex flex-wrap items-center justify-center">
              <span
                >Última Sincronização:
                {{
                  configIbio.last_update | dateTime('dateTimeNoSecond', 'h')
                }}</span
              >
            </div>
          </div>
        </div>
        <div class="vx-row" v-if="permitSyncOn">
          <div class="vx-col w-full">
            <div class="mt-4 flex flex-wrap items-center justify-center">
              <vx-tooltip
                text="A sincronização está sendo executada em background. Só é permitido sincronizar agendas a cada 45 minutos. Atualize a página para tentar novamente!"
                position="top"
              >
                <feather-icon
                  icon="InfoIcon"
                  svgClasses="h-4 w-4"
                  class="mr-1"
                />

                <span
                  >Nova Sincronização após:
                  {{ permitSyncOn | dateTime('dateTimeNoSecond', 'h') }}</span
                >
              </vx-tooltip>
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="mt-4 flex flex-wrap items-center justify-center">
              <vs-button
                class="w-80"
                :disabled="
                  !configIbio.id || permitSyncOn || !statusLoginIbio.status
                "
                @click="fetchNotSyncedSchedules"
                >Importar agenda do IBIO</vs-button
              >
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="mt-4 flex flex-wrap items-center justify-center">
              <vs-button
                class="w-80"
                color="danger"
                :disabled="!configIbio.id"
                @click="confirmDisable"
                >Desabilitar importação</vs-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <vs-alert
      class="mt-2"
      color="danger"
      title="ATENÇÃO:"
      :active="!statusLoginIbio.status"
      icon-pack="feather"
      close-icon="icon-x"
    >
      {{ statusLoginIbio.message }} <br />
    </vs-alert>
    <vs-alert
      class="mt-2"
      color="success"
      title="ATENÇÃO:"
      :active="statusLoginIbio.status"
      icon-pack="feather"
      close-icon="icon-x"
    >
      As configurações de login IBIO estão corretas. <br />
    </vs-alert>
  </div>
</template>

<script>
import moment from 'moment'
import ListSchedule1 from '@/components/schedule/practical/ListSchedule1'
export default {
  components: {
    ListSchedule1
  },
  data () {
    return {
      selectedSchedules: [],
      schedules: [],
      listSchedulePopup: false,
      startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      endDate: moment().add(7, 'days').format('YYYY-MM-DD'),
      configIbio: {
        id: null,
        login: null,
        password: null,
        last_update: null
      },
      permitSyncOn: null,
      statusLoginIbio: {
        status: true,
        code: '',
        message: ''
      }
    }
  },
  computed: {
    validateconfigIbio () {
      return !this.errors.any('configIbio')
    }
  },
  methods: {
    async fetchNotSyncedSchedules () {
      const valid = await this.isValidSync()
      if (!valid) return
      this.$http.post(
        `${process.env.VUE_APP_API_URL}/notSyncedSchedules`,
        {
          startDate: this.startDate,
          endDate: this.endDate
        }
      ).then(e => {
        if (e.data && e.data.length) {
          this.listSchedulePopup = true
          this.schedules = e.data
        } else {
          this.syncIbioNow()
        }
      }).catch(this.mixinCatch)

    },
    async syncIbioNow () {
      this.listSchedulePopup = false
      if (await this.isValidSync()) {
        this.sincIbio()
      }
    },
    async isValidSync () {
      const valid = await this.$validator.validateAll()
      if (valid) {
        return true
      }
      return false
    },
    async checkIBioLogin () {
      const resp = await this.$http.post(
        `${process.env.VUE_APP_API_URL}/checkIbioLogin`,
        this.configIbio
      )
      this.statusLoginIbio = resp.data
    },

    async save () {
      try {
        this.$vs.loading()
        const resp = await this.$http.put(
          `${process.env.VUE_APP_API_URL}/updateIbio`,
          this.configIbio
        )
        this.configIbio = resp.data
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Alterados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
        this.checkIBioLogin()
      } catch (error) {
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    async sincIbio () {
      try {
        this.configIbio.endDate = this.endDate
        this.configIbio.startDate = this.startDate
        this.configIbio.except = this.selectedSchedules.map(e => e.id)
        this.$vs.loading()
        const resp = await this.$http.post(
          `${process.env.VUE_APP_API_URL}/sincronizeIbio`,
          this.configIbio
        )
        this.configIbio.last_update = resp.data.last_update
        this.permitSyncOn = moment(this.configIbio.last_update)
          .add(45, 'minutes')
          .format('YYYY-MM-DD H:mm:ss')

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Enviados para Fila de Sincronização.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check',
          position: 'top-center'
        })
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    confirmDisable () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar',
        text: 'Tem certeza que deseja desabilitar a sincronização IBIO?',
        accept: this.disable,
        acceptText: 'Desabilitar',
        cancelText: 'Cancelar'
      })
    },
    async disable () {
      try {
        this.$vs.loading()
        await this.$http.delete(`${process.env.VUE_APP_API_URL}/disableIbio`)
        this.$vs.loading.close()

        this.configIbio = {
          id: null,
          login: null,
          password: null,
          last_update: null
        }

        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Desabilitado.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    }
  },
  async created () {
    const configIbio = await this.$http.get(
      `${process.env.VUE_APP_API_URL}/configIbio`
    )
    this.configIbio = configIbio.data.id ? configIbio.data : { id: null, login: null, password: null, last_update: null }
    if (this.configIbio.last_update) {
      const lastSync = moment(this.configIbio.last_update)
      this.permitSyncOn = moment().diff(lastSync, 'minutes') < 45 ? moment(this.configIbio.last_update).add(45, 'minutes').format('YYYY-MM-DD H:mm:ss') : null
    }
    this.checkIBioLogin()
  }
}
</script>

<style>
</style>
