<template>
  <div id="configurations-schedule-tab">
    <vs-table noDataText="" :data="[]" class="bordered" hoverFlat style="overflow: -webkit-paged-y;">
      <template slot="thead">
        <vs-th style="font-size: 11px;"><span>Tipo de Relatório</span></vs-th>
        <vs-th style="font-size: 11px;"><span>Configuração de Rodapé</span></vs-th>
      </template>
      <template slot-scope="{  }">
        <vs-tr style="font-size: 12px; opacity: 1 !important">
          <vs-td style="text-align:left">
            <span class="cursor-default font-semibold">Agendamentos</span>
          </vs-td>
          <vs-td>
            <vs-textarea v-model="configs.reporting_footer.practicalClass" class="mb-0" height="100px" width="100%" />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-divider></vs-divider>

    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="flex flex-wrap items-center justify-end">
          <vs-button @click="save">Salvar</vs-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  computed: {
    companyConfigurations () {
      return this.$store.getters.companyConfigurations
    }
  },
  data () {
    return {
      configs: {
        type_configuration: 'BY_COMPANY',
        reporting_footer: { practicalClass: '' }
      }
    }
  },
  methods: {
    async save () {
      try {
        this.$vs.loading()

        await this.$http.post(`${process.env.VUE_APP_API_URL}/configuration`, this.configs)

        const company = JSON.parse(JSON.stringify(this.$store.state.AppActiveUser.company))
        company.configuration = JSON.parse(JSON.stringify(this.configs))
        this.$store.commit('UPDATE_USER_INFO', { company })

        this.$nextTick(() => {
          this.$vs.loading.close()
        })

        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Alterados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.log(error)
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    }
  },
  mounted () {
    this.configs = JSON.parse(JSON.stringify(this.companyConfigurations))
    this.configs.type_configuration = 'BY_COMPANY'
  }
}
</script>

<style>
  #configurations-schedule-tab td {
    border: 1px solid #c0c0c0;
    line-height: 2;
  }
  #configurations-schedule-tab .vs-table--thead {
    position: initial;
  }
  #configurations-schedule-tab th {
    border: 1px solid #c0c0c0;
  }
  #configurations-schedule-tab .vs-con-table .vs-con-tbody {
    border: 1px solid #c0c0c0;
  }
  .tab-action-btn-fill-conatiner.con-vs-tabs .vs-tabs--content {
    padding: 0px 10px !important;
  }
  .vs-table--not-data {
    padding: 0px;
  }
</style>
