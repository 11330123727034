var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "configuration-general-tab" } },
    [
      _c(
        "vs-tabs",
        {
          attrs: {
            id: "configuration-general-internal-tab",
            position: "left",
            color: "primary",
          },
        },
        [
          _c("vs-tab", { attrs: { label: "Financeiro" } }, [
            _c(
              "div",
              { staticClass: "tab-text" },
              [_c("financial", { staticClass: "mt-4" })],
              1
            ),
          ]),
          _c("vs-tab", { attrs: { label: "Sinc. IBio" } }, [
            _c(
              "div",
              { staticClass: "tab-text" },
              [_c("ibio", { staticClass: "mt-4" })],
              1
            ),
          ]),
          _c("vs-tab", { attrs: { label: "Visual" } }, [
            _c(
              "div",
              { staticClass: "tab-text" },
              [_c("visual", { staticClass: "mt-4" })],
              1
            ),
          ]),
          _c("vs-tab", { attrs: { label: "Relatórios" } }, [
            _c(
              "div",
              { staticClass: "tab-text" },
              [_c("report", { staticClass: "mt-4" })],
              1
            ),
          ]),
          _c("vs-tab", { attrs: { label: "Recibos" } }, [
            _c(
              "div",
              { staticClass: "tab-text" },
              [_c("receipt", { staticClass: "mt-4" })],
              1
            ),
          ]),
          _c("vs-tab", { attrs: { label: "Contratos" } }, [
            _c(
              "div",
              { staticClass: "tab-text" },
              [_c("contract", { staticClass: "mt-4" })],
              1
            ),
          ]),
          _c("vs-tab", { attrs: { label: "Alertas" } }, [
            _c(
              "div",
              { staticClass: "tab-text" },
              [_c("alert", { staticClass: "mt-4" })],
              1
            ),
          ]),
          _c("vs-tab", { attrs: { label: "E-mail" } }, [
            _c(
              "div",
              { staticClass: "tab-text" },
              [_c("email", { staticClass: "mt-4" })],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }