<template>
  <div id="configuration-cfc-data-tab">
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="flex items-start flex-col lg:flex-row">
            <input id="fileUpload" @change="onFileSelected" type="file" hidden>
            <div @mouseover="mouseOver = true" @mouseleave="mouseOver = false" @click="chooseFile" id="logo-loading" class="cursor-pointer p-2 vs-con-loading__container mt-6" style="position: absolute; width:200px; height:200px">
              <feather-icon v-if="mouseOver" svgClasses="h-16 w-16 stroke-current text-success" icon="CameraIcon" style="margin-left: 55px; margin-top: 54px" />
            </div>
            <img :src="(company.logo && company.logo.url) ? company.logo.url : noImage" class="mt-6 mr-8 rounded h-56 w-56" @load="closeLoading" />
            <div class="w-full">
              <div class="vx-row">
                <div class="vx-col md:w-1/2 w-full mt-0">
                  <vs-input class="w-full mt-0" label="Razão Social" v-model="company.corporate_name" data-vv-as="Razão Social" v-validate.initial="'required'" name="corporate_name" />
                  <span class="text-danger text-sm" v-show="errors.has('corporate_name')">{{ errors.first('corporate_name') }}</span>
                </div>
                <div class="vx-col md:w-1/2 w-full mt-0">
                  <vs-input class="w-full mt-0" label="Nome Fantasia" v-model="company.trading_name" data-vv-as="Nome Fantasia" v-validate.initial="'required'" name="trading_name" />
                  <span class="text-danger text-sm" v-show="errors.has('trading_name')">{{ errors.first('trading_name') }}</span>
                </div>
              </div>

              <div class="vx-row">
                <div class="vx-col md:w-1/3 w-full mt-2">
                  <vs-input class="w-full" label="CNPJ" v-model="company.cnpj" v-mask="['###.###.###-##','##.###.###/####-##']" data-vv-as="CNPJ" v-validate="'cpf'" name="cnpj" />
                  <span class="text-danger text-sm" v-show="errors.has('cnpj')">{{ errors.first('cnpj') }}</span>
                </div>

                <div class="vx-col md:w-1/3 w-full mt-2">
                  <vs-input class="w-full" label="IE" v-model="company.ie" />
                </div>

                <div class="vx-col md:w-1/3 w-full mt-2">
                  <vs-input class="w-full" label="Reg. DETRAN" v-model="company.register_detran" />
                </div>

              </div>

              <div class="flex items-end mt-2">
                <feather-icon icon="MapPinIcon" class="mr-2" svgClasses="w-5 h-5" />
                <span class="leading-none font-medium">Endereço</span>
              </div>
              <hr>

              <div class="vx-row">
                <div class="vx-col md:w-1/4 w-full mt-2">
                  <vs-input class="w-full" label="CEP" placeholder="CEP" v-model="company.addresses.zip_code" v-mask="['#####-###']" @keyup="searchZipCode" />
                </div>

                <div class="vx-col md:w-1/4 w-full mt-2">
                  <label class="vs-input--label">Logradouro</label>
                  <v-select v-model="company.addresses.logradouro" :reduce="option => option.value" clearable :options="logradouroOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                    <template #no-options="{}">
                      Desculpe, nenhum resultado encontrado.
                    </template>
                  </v-select>
                </div>

                <div class="vx-col md:w-1/4 w-full mt-2">
                  <vs-input class="w-full" label="Endereço" v-model="company.addresses.street" />
                </div>

                <div class="vx-col md:w-1/4 w-full mt-2">
                  <vs-input class="w-full" label="Número" v-model="company.addresses.number" type="number" placeholder="S/N" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full">
          <vs-input class="w-full mt-2" label="Complemento" v-model="company.addresses.complement" />
        </div>
        <div class="vx-col md:w-1/5 w-full">
          <vs-input class="w-full mt-2" label="Bairro" v-model="company.addresses.neighborhood" />
        </div>
        <div class="vx-col md:w-1/4_ w-full mt-2">
          <label class="vs-input--label">Cidade</label>
          <v-select class="vue_select_drop_size_200" v-model="company.addresses.city_id" :reduce="option => option.value" :placeholder="cityOptions.length ? 'Selecione' : 'Selecione um estado'" :options="cityOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-2">
          <label class="vs-input--label">Estado</label>
          <v-select class="vue_select_drop_size_200" v-model="company.addresses.state_id" :reduce="option => option.value" @input="fillCity(company.addresses.state_id), company.addresses.city_id = null" :options="states" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-2">
          <label class="vs-input--label">País</label>
          <v-select v-model="company.addresses.country" :reduce="option => option.value" :clearable="false" :options="countryOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <div class="flex items-end mt-2">
        <feather-icon icon="PhoneIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Contato</span>
      </div>
      <hr>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full" v-for="(phone,i) in company.phones" :key="i">
          <vs-input class="w-full mt-2" v-mask="['(##)#####-####','(##)####-####']" :label="phone.type === 'phone' ? 'Telefone' : phone.type === 'mobile' ? 'Celular' : phone.type === 'other' ? 'Outro' : ''" v-model="phone.phone" />
        </div>
        <div class="vx-col md:w-2/5 w-full">
          <vs-input class="w-full mt-2" label="E-mail" data-vv-as="E-mail" v-model="company.email" v-validate="'email'" name="email" />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="save" :disabled="!validateForm">Salvar Alterações</vs-button>
          </div>
        </div>
      </div>

  </div>

</template>

<script>
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
import moduleStates from '@/store/state/moduleStates.js'
import moduleCompany from '@/store/company/moduleCompany.js'

export default {
  components: {
    vSelect
  },
  computed: {
    validateForm () {
      return !this.errors.any()
    },
    states () {
      return this.$store.getters['states/getStates']
    }
  },
  data () {
    return {
      mouseOver: null,
      mouseLeave: null,
      noImage: require('@/assets/images/noImg.jpg'),
      company: {
        logo: null,
        corporate_name: '',
        trading_name: '',
        cnpj: '',
        ie: '',
        register_detran: '',
        email: '',
        emails: [],

        phones: [
          {type: 'mobile', phone: null},
          {type: 'phone', phone: null},
          {type: 'other', phone: null}
        ],
        addresses: {
          logradouro: null,
          street: null,
          number: null,
          complement: null,
          neighborhood: null,
          zip_code: null,
          observation: null,
          type_address: 'res',
          state_id: null,
          city_id: null,
          country: 1 // Brasil
        }
      },
      countryOptions: [{ label: 'Brasil', value: 1 }],
      logradouroOptions: [
        { label: 'Rua', value: 'Rua' },
        { label: 'Avenida', value: 'Avenida' },
        { label: 'Rodovia', value: 'Rodovia' },
        { label: 'Estrada', value: 'Estrada' },
        { label: 'Lote', value: 'Lote' },
        { label: 'Servidão', value: 'Servidão' },
        { label: 'Quadra', value: 'Quadra' },
        { label: 'Travessa', value: 'Travessa' },
        { label: 'Linha', value: 'Linha' }
      ],

      cityOptions:[]
    }
  },

  methods: {
    openLoading () {
      this.$vs.loading({
        container: '#logo-loading',
        scale: 0.6
      })
    },
    closeLoading () {
      this.$vs.loading.close('#logo-loading > .con-vs-loading')
    },
    chooseFile () {
      document.getElementById('fileUpload').click()
    },
    onFileSelected (event) {
      const file = event.target.files[0]
      if (file.size > 200000) {
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Este arquivo é muito grande. O tamanho máximo permitido é de 200kb!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }

      const self = this
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        if (!self.company.logo) {
          self.company.logo = {
            url: reader.result
          }
        } else {
          self.company.logo.url = reader.result
        }
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    },
    async save () {
      try {
        this.$vs.loading()
        await this.$store.dispatch('company/update', this.company)
        this.$vs.loading.close()
        if (this.company.emails.length) this.company.emails[0].email = this.company.email
        else this.company.emails.push({email: JSON.parse(JSON.stringify(this.company.email))})
        this.$store.commit('UPDATE_USER_INFO', {company: JSON.parse(JSON.stringify(this.company))})

        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: 'Algo saiu errado. Por favor chame o suporte.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    async searchZipCode ($event) {
      const zip_code = this.company.addresses.zip_code.trim().replace(/[^0-9]/g, '')

      if (zip_code.length === 8 && ($event.keyCode < 37 || $event.keyCode > 40)) { // $event.keyCode evita chamadas de API ao teclar setas

        this.$http._noHeaders = true //enviando um get sem Autorization no header
        const resp = await this.$http.get(`https://viacep.com.br/ws/${zip_code}/json`)
        if (resp.data.erro) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: 'Este CEP não existe ou não foi encontrado.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }

        this.company.addresses.neighborhood = resp.data.bairro
        this.company.addresses.complement = resp.data.complemento

        //abaixo testo a primeira palavra da rua (logradouro), se existir removo da rua e seleciono o logradouro
        const rua = /Rua /i
        const avenida = /Avenida /i
        const rodovia = /Rodovia /i
        const estrada = /Estrada /i
        const lote = /Lote /i
        const servidao = /Servidão /i
        const quadra = /Quadra /i
        const travessa = /Travessa /i
        const linha = /Linha /i

        if (rua.test(resp.data.logradouro)) {
          this.company.addresses.logradouro = 'Rua'
          const regEx = new RegExp('Rua ', 'ig')
          this.company.addresses.street = resp.data.logradouro.replace(regEx, '')
        } else if (avenida.test(resp.data.logradouro)) {
          this.company.addresses.logradouro = 'Avenida'
          const regEx = new RegExp('Avenida ', 'ig')
          this.company.addresses.street = resp.data.logradouro.replace(regEx, '')
        } else if (rodovia.test(resp.data.logradouro)) {
          this.company.addresses.logradouro = 'Rodovia'
          const regEx = new RegExp('Rodovia ', 'ig')
          this.company.addresses.street = resp.data.logradouro.replace(regEx, '')
        } else if (estrada.test(resp.data.logradouro)) {
          this.company.addresses.logradouro = 'Estrada'
          const regEx = new RegExp('Estrada ', 'ig')
          this.company.addresses.street = resp.data.logradouro.replace(regEx, '')
        } else if (lote.test(resp.data.logradouro)) {
          this.company.addresses.logradouro = 'Lote'
          const regEx = new RegExp('Lote ', 'ig')
          this.company.addresses.street = resp.data.logradouro.replace(regEx, '')
        } else if (servidao.test(resp.data.logradouro)) {
          this.company.addresses.logradouro = 'Servidão'
          const regEx = new RegExp('Servidão ', 'ig')
          this.company.addresses.street = resp.data.logradouro.replace(regEx, '')
        } else if (quadra.test(resp.data.logradouro)) {
          this.company.addresses.logradouro = 'Quadra'
          const regEx = new RegExp('Quadra ', 'ig')
          this.company.addresses.street = resp.data.logradouro.replace(regEx, '')
        } else if (travessa.test(resp.data.logradouro)) {
          this.company.addresses.logradouro = 'Travessa'
          const regEx = new RegExp('Travessa ', 'ig')
          this.company.addresses.street = resp.data.logradouro.replace(regEx, '')
        } else if (linha.test(resp.data.logradouro)) {
          this.company.addresses.logradouro = 'Linha'
          const regEx = new RegExp('Linha ', 'ig')
          this.company.addresses.street = resp.data.logradouro.replace(regEx, '')
        } else {
          this.company.addresses.street = resp.data.logradouro
        }

        const state = this.states.find(o => o.initials === resp.data.uf)
        this.company.addresses.state_id = state.value
        await this.fillCity(state.value, resp.data.localidade)
      }
    },

    async fillCity (stateId, city = null) {
      this.cityOptions = []
      try {
        if (stateId) {
          const resp = await this.$http.get(`${process.env.VUE_APP_API_URL}/cities/${stateId}`)
          // this.cityOptions.push({ value: null, label: 'Selecione' })

          for (const k in resp.data) {
            this.cityOptions.push({ value: resp.data[k].id, label: resp.data[k].city })
          }
          if (city) {
            //procura pelo id, se não encontrar peocura pelo nome (motivo: da api recebo ID e do CEP recebo nome)
            let ct = this.cityOptions.find(x => x.value === city)
            if (!ct) {
              ct = this.cityOptions.find(x => x.label === city)
            }
            this.company.addresses.city_id = ct.value
          }
        }
      } catch (error) { console.error(error) }
    }
  },

  created () {
    if (!moduleStates.isRegistered) {
      this.$store.registerModule('states', moduleStates)
      moduleStates.isRegistered = true
    }
    this.$store.dispatch('states/fetchStates')

    if (!moduleCompany.isRegistered) {
      this.$store.registerModule('company', moduleCompany)
      moduleCompany.isRegistered = true
    }

    const company = JSON.parse(JSON.stringify(this.$store.state.AppActiveUser.company))

    if (company.addresses) {
      company.addresses.country = 1 // Brasil
    } else {
      company.addresses = {
        country: 1 // Brasil
      }
    }
    if (company.addresses.state_id) {
      if (company.addresses.city_id) {
        this.fillCity(company.addresses.state_id, company.addresses.city_id)
      } else {
        this.fillCity(company.addresses.state_id)
      }
    }

    if (company.emails && company.emails.length) {
      Object.assign(company, {email: company.emails[0].email})
    }

    const sortPhonesBy = ['mobile', 'phone', 'other']
    //AJUSTANDO OS TELEFONES VAZIOS
    if (!company.phones) company.phones = [
      {type: 'mobile', phone: null},
      {type: 'phone', phone: null},
      {type: 'other', phone: null}
    ]
    if (!company.phones.filter(phone => phone.type === 'mobile').length) {
      company.phones.unshift({id: null, phone: null, type: 'mobile' })
    }
    if (!company.phones.filter(phone => phone.type === 'phone').length) {
      company.phones.push({id: null, phone: null, type: 'phone' })
    }
    if (!company.phones.filter(phone => phone.type === 'other').length) {
      company.phones.push({id: null, phone: null, type: 'other' })
    }
    //ORDENANDO OS TELEFONES NA SEQUÊNCIA ABAIXO
    company.phones.sort((a, b) => sortPhonesBy.indexOf(a.type) - sortPhonesBy.indexOf(b.type))
    this.company = company
  }
}
</script>

<style>
  hr {
    margin-top: 2px;
    border: none;
    height: 1px;
    /* Set the hr color */
    color: rgb(204, 204, 204); /* old IE */
    background-color: rgb(204, 204, 204); /* Modern Browsers */
  }
  .vue_select_drop_size_200 .vs__dropdown-menu {
    max-height: 200px;
  }
</style>
