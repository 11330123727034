<template>
  <div id="birthday-automatic-email-tab">

      <vs-alert v-if="!mailConfig" class="mt-2" color="primary" title="Orientações:" :active="!mailConfig" icon-pack="feather">
        <p>Para enviar emails é necessário configurar sua conta de email.</p>
        <p>Nesta mesma tela, acesse a aba Geral e dentro desta nova tela, acesse a aba E-mail.</p>
        <p>Efetue as configurações e retorne aqui para configurar o envio automático de e-mails.</p>
      </vs-alert>

      <div v-else>
        <div class="vx-row">
          <div class="vx-col md:w-full w-full">
            <vs-checkbox v-model="configAutoSend.birthday.status" class="ml-0 mr-1 mt-1">Habilitar o envio automático de emails para aniversariantes.</vs-checkbox>
          </div>
          <div class="vx-col md:w-3/4 w-full mt-2">
            <label class="vs-input--label">Enviar quantos dias antes?</label>
            <v-select appendToBody class="vue_select_drop_size_200" :disabled="!configAutoSend.birthday.status" v-model="configAutoSend.birthday.daysBefore"
              multiple placeholder="Selecione"
              :options="[{ value: '0', label: 'No dia' },
                        { value: '1', label: '1 dia antes' },
                        { value: '2', label: '2 dias antes' },
                        { value: '3', label: '3 dias antes' },
                        { value: '4', label: '4 dias antes' },
                        { value: '5', label: '5 dias antes' },
                        { value: '6', label: '6 dias antes' },
                        { value: '7', label: '7 dias antes' }]" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
          <div class="vx-col md:w-1/4 w-full mt-2">
            <label class="vs-input--label">Em que Horário?</label>
            <v-select appendToBody class="vue_select_drop_size_200" :disabled="!configAutoSend.birthday.status" v-model="configAutoSend.birthday.time"
              placeholder="Selecione" :reduce="option => option.value" :clearable="false"
              :options="[{ value: '06:00:00', label: '06:00h' },
                        { value: '21:30:00', label: '21:30h' } ]" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-2/5 w-full">
            <label class="vs-input--label">Digite o texto utilizando o termo [nome_aluno] onde desejar mostrar o mesmo.</label>
            <vs-textarea :disabled="!configAutoSend.birthday.status" v-model="configAutoSend.birthday.text" class="mb-0" height="100px" width="100%" />
          </div>
          <div class="vx-col md:w-3/5 w-full">
            <h2 class="text-center">Prévia</h2>
            <vs-input class="w-full mt-0" label="Assunto" :disabled="!configAutoSend.birthday.status" v-model="configAutoSend.birthday.subject"
              data-vv-scope="birthday" data-vv-as="Assunto" v-validate.initial="'required'" name="subject" />
            <span class="text-danger text-sm" v-show="errors.has('birthday.subject')">{{ errors.first('birthday.subject') }}</span>
              <table align="center" border="0" cellpadding="0" cellspacing="0" style="width:650px;">
                <tbody>
                  <tr>
                      <td><img alt="" height="235" src="https://app.sigcfc.com.br/assets/images/emailTemplate/birthday/2_01.jpg" width="650"></td>
                  </tr>
                  <tr>
                      <td>
                          <table align="center" border="0" cellpadding="0" cellspacing="0" style="width:650px;">
                              <tbody>
                              <tr>
                                  <td><img alt="" height="260" src="https://app.sigcfc.com.br/assets/images/emailTemplate/birthday/2_02.jpg" width="124"></td>
                                  <td style="text-align: justify;" width="100%"><span style="color: #111111; font-family: Verdana, Arial, Helvetica, sans-serif; font-size: 13px;">{{ configAutoSend.birthday.text }}</span></td>
                                  <td><img alt="" height="260" src="https://app.sigcfc.com.br/assets/images/emailTemplate/birthday/2_04.jpg" width="140"></td>
                              </tr>
                              </tbody>
                          </table>
                      </td>
                  </tr>
                  <tr>
                      <td><img alt="" height="107" src="https://app.sigcfc.com.br/assets/images/emailTemplate/birthday/2_05.jpg" width="650"></td>
                  </tr>
                  <tr align="center" v-if="company.logo && company.logo.url">
                      <td>
                          <table class="mt-2" align="center" border="0" cellpadding="0" cellspacing="0" style="width:160px;">
                              <tbody>
                              <tr>
                                  <td align="center">
                                      <img alt="Logo" height="90" :src="'https://app-sigcfc-bucket.s3-us-west-2.amazonaws.com/companyLogo/'+company.logo.filename">
                                  </td>
                              </tr>
                              </tbody>
                          </table>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <table align="center" border="0" cellpadding="0" cellspacing="0" style="width:100%;">
                              <tbody>
                              <tr><td><hr></td></tr>
                              <tr>
                                  <td align="center" style="font-size: 10px"><a href="#" style="color: gray">Não desejo mais receber notificações por email.</a></td>
                              </tr>
                              </tbody>
                          </table>
                      </td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>

        <vs-divider></vs-divider>

        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex flex-wrap items-center justify-end">
              <vs-button :disabled="!validateForm" @click="save('birthday')">Salvar configurações de aniversário</vs-button>
            </div>
          </div>
        </div>
      </div>

  </div>
</template>

<script>
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')

export default {
  props: {
    mailConfig: {
      type: [Object, Boolean],
      default: false
    },
    automaticEmails: { type: Array, required: true }
  },
  components: {
    vSelect
  },
  computed: {
    validateForm () {
      return !this.errors.any('birthday')
    },
    company () {
      return this.$store.state.AppActiveUser.company
    }
  },
  data () {
    return {
      configAutoSend: {
        birthday: {
          status: false,
          identification: 'birthday',
          type: null,
          daysBefore: [{ value: '5', label: '5 dias antes' }],
          daysAfter: [],
          time: '21:30:00',
          subject: 'Feliz aniversário.',
          text: 'Feliz aniversário [nome_aluno]'
        }
      }
    }
  },

  methods: {
    async save (identification) {
      const configAutoSend = {}
      Object.assign(configAutoSend, this.configAutoSend[identification])
      try {
        const response = await this.$http.put(`${process.env.VUE_APP_API_URL}/automaticEmail/`, configAutoSend)
        if (response.data.type === 'success') {
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: response.data.message,
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        } else if (response.data.type === 'warning') {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        }
      } catch (error) {
        console.log(error)
        this.$vs.notify({
          time: 5000,
          title: 'Erro',
          text: error.response.data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    }
  },
  async mounted () {
    this.automaticEmails.forEach(element => {
      if (element.identification === 'birthday') {
        this.configAutoSend.birthday = element
        this.configAutoSend.birthday.status = !!this.configAutoSend.birthday.status
      }
    })
  }
}
</script>

<style lang="scss">
  .vs-tooltip {
    z-index: 99999;
  }
</style>
