<template>
  <div id="page-tree-account-plans">

    <dialog-sig :show="popupDeleteConfirm" title="ALERTA" icon="warning"
      @action="deleteAccountPlan(node)"
      @cancel="popupDeleteConfirm = false">
        Tem certeza que deseja excluir este Plano de Contas? <br>
        Esta ação é irreversível e pode afetar relatórios, estatísticas e gráficos.
    </dialog-sig>

    <vx-card>
      <vs-button icon-pack="feather" icon="icon-plus-circle" @click="addNode">Adicionar Plano de Contas Principal</vs-button>
      <vs-divider></vs-divider>
      <vue-tree-list
        @click="onClick"
        @change-name="onChangeName"
        @delete-node="onDel"
        @add-node="onAddNode"
        @drop="onDropNode"
        :model="data"
        default-tree-node-name="Novo Plano de Conta"
        default-leaf-node-name="new leaf"
        v-bind:default-expanded="false"
      >
        <template v-slot:leafNameDisplay="slotProps">
          <span> {{ slotProps.model.name }} <!-- <span class="muted">#{{ slotProps.model.id }}</span> --> </span>
        </template>
        <i class="material-icons-outlined icon mt-0" title="Adicionar" slot="addTreeNodeIcon">add_circle_outline</i>
        <i class="material-icons-outlined icon" title="Editar" @click="node = { changed: false }" slot="editNodeIcon"> mode_edit_outline </i>
        <i class="material-icons-outlined icon" title="Excluir" slot="delNodeIcon"> delete_outline </i>
        <i class="material-icons-outlined icon" slot="treeNodeIcon"> folder_open </i>
        <span class="icon" slot="addLeafNodeIcon"></span> <!-- Vazio para que não mostre esta opção -->
        <span class="icon" slot="leafNodeIcon"></span> <!-- Vazio para que não mostre esta opção -->
      </vue-tree-list>
      <!-- <button @click="getNewTree">Get new tree</button>
      <pre>
        {{newTree}}
      </pre> -->

    </vx-card>
  </div>
</template>

<script>
import { VueTreeList, Tree, TreeNode } from 'vue-tree-list'
const dialogSig = () => import('@/components/dialog/dialogSig.vue')
export default {
  name: 'treeAccountPlans',
  components: {
    VueTreeList,
    dialogSig
  },

  props: {
    accountPlansProp : { type: Array,  required: true }
  },

  data () {
    return {
      popupDeleteConfirm: false,
      newTree: {},
      data: new Tree(this.accountPlansProp),
      node: null
    }
  },

  methods: {
    addNode () {
      const node = new TreeNode({ name: 'Novo Plano de Conta', isLeaf: false })
      if (!this.data.children) this.data.children = []
      this.data.addChildren(node)
      this.store(node)
    },

    onAddNode (params) {
      this.store(params)
    },

    async store (params) {
      try {
        const res = await this.$store.dispatch('accountPlan/store', { name: params.name, pid: params.pid })
        params.id = res.data.id
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
        this.popupDeleteConfirm = false
      } catch (error) {
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: 'Algo saiu errado. Por favor chame o suporte.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

    // COMO NÃO RECEBO O NODE EM PARAMS AO PRESSIONAR ENTER NA EDIÇÃO, PRECISEI FAZER UMA MANOBRA
    onChangeName (params) {
      if (params.node && !this.node.changed) {
        this.node = {
          id:  params.node.id,
          name:  params.oldName,
          pid:  params.node.pid,
          changed: true
        }
      }
      if (params.eventType === 'blur' && this.node.id) {
        if (this.node.name !== params.newName) {
          this.update(this.node, params)
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Nenhuma Alteração Detectada.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        }
      } else if (params.eventType === 'blur') {
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Nenhuma Alteração Detectada.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      }
    },

    onDropNode ({ node }) {
      this.update(node, {newName: node.name})
    },

    async update (oldNode, params) {
      try {
        await this.$store.dispatch('accountPlan/update', { id: oldNode.id, name: params.newName, pid: oldNode.pid })
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Alterados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
        this.popupDeleteConfirm = false
      } catch (error) {
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: 'Algo saiu errado. Por favor chame o suporte.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

    onDel (node) {
      console.log(node.children)
      if (node.children && node.children.length) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Este plano de conta possui subplanos (filhos), por isso não pode ser excluído! Remova todos os filhos primeiro.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      } else {
        this.node = node
        this.popupDeleteConfirm = true
      }
    },

    async deleteAccountPlan (accountPlan) {
      try {
        await this.$store.dispatch('accountPlan/delete', accountPlan.id)
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Excluídos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
        accountPlan.remove()
        this.popupDeleteConfirm = false
      } catch (error) {
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: 'Algo saiu errado. Por favor chame o suporte.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

    onClick (params) {
      console.log(params)
    }

    // getNewTree () {
    //   const vm = this
    //   function _dfs (oldNode) {
    //     const newNode = {}

    //     for (const k in oldNode) {
    //       if (k !== 'children' && k !== 'parent') {
    //         newNode[k] = oldNode[k]
    //       }
    //     }

    //     if (oldNode.children && oldNode.children.length > 0) {
    //       newNode.children = []
    //       for (let i = 0, len = oldNode.children.length; i < len; i++) {
    //         newNode.children.push(_dfs(oldNode.children[i]))
    //       }
    //     }
    //     return newNode
    //   }

    //   vm.newTree = _dfs(vm.data)
    // }
  }
}
</script>
<!--
<style lang="less" rel="stylesheet/less">
  .vtl {
    .vtl-drag-disabled {
      background-color: #d0cfcf;
      &:hover {
        background-color: #d0cfcf;
      }
    }
    .vtl-disabled {
      background-color: #d0cfcf;
    }
  }
</style>
-->

<style scoped>
  .icon:hover {
    cursor: pointer;
  }

  .muted {
    color: gray;
    font-size: 80%;
  }
</style>
