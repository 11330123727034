var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { direction: _vm.$vs.rtl ? "rtl" : "ltr" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup80",
          attrs: { title: "Alterar Serviço", active: _vm.popupUpdateService },
          on: {
            "update:active": function ($event) {
              _vm.popupUpdateService = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    label: "Serviço",
                    "data-vv-as": "Serviço",
                    "data-vv-scope": "service",
                    name: "name",
                  },
                  model: {
                    value: _vm.service.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.service, "name", $$v)
                    },
                    expression: "service.name",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("service.name"),
                        expression: "errors.has('service.name')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Campo Obrigatório.")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Categoria"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_150",
                  attrs: {
                    reduce: (option) => option.value,
                    options: _vm.cnhOptions,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.service.category_cnh,
                    callback: function ($$v) {
                      _vm.$set(_vm.service, "category_cnh", $$v)
                    },
                    expression: "service.category_cnh",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Plano de contas"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "vue_select_drop_size_150",
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    "data-vv-as": "Plano de Contas",
                    "data-vv-scope": "service",
                    name: "accountPlan",
                    options: _vm.accountPlans,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.service.account_plan_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.service, "account_plan_id", $$v)
                    },
                    expression: "service.account_plan_id",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("service.accountPlan"),
                        expression: "errors.has('service.accountPlan')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Campo Obrigatório.")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Tipo de Serviço"),
                ]),
                _c("v-select", {
                  staticStyle: { "background-color": "white" },
                  attrs: {
                    clearable: true,
                    reduce: (option) => option.value,
                    options: _vm.typeServiceOptions,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.service.type_service,
                    callback: function ($$v) {
                      _vm.$set(_vm.service, "type_service", $$v)
                    },
                    expression: "service.type_service",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/3 w-full mt-0" }, [
              _c("label", { staticClass: "vs-input--label" }, [
                _c("strong", [_vm._v("Valor Taxas")]),
              ]),
              _c("br"),
              _c("h5", { staticClass: "mt-2" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.service.totalTax
                        ? _vm.service.totalTax.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })
                        : "R$ 0,00"
                    ) +
                    "\n        "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Valor"),
                ]),
                _c("currency-input", {
                  staticClass:
                    "w-full vs-inputx vs-input--input normal hasValue",
                  attrs: {
                    currency: { prefix: "R$ " },
                    valueAsInteger: false,
                    distractionFree: false,
                    precision: 2,
                    autoDecimalMode: true,
                    valueRange: { min: 0 },
                    allowNegative: false,
                  },
                  model: {
                    value: _vm.service.value,
                    callback: function ($$v) {
                      _vm.$set(_vm.service, "value", $$v)
                    },
                    expression: "service.value",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "vx-row flex flex-wrap items-center justify-center",
            },
            [
              _c(
                "div",
                { staticClass: "vx-col md:w-4/5 w-full mt-2" },
                [
                  _c(
                    "vs-divider",
                    {
                      staticClass: "d-theme-border-grey-light m-0",
                      attrs: { position: "left" },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "MenuIcon", svgClasses: "w-4 h-4" },
                      }),
                      _vm._v("Ítens do Serviço\n        "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "vx-col md:w-1/5 w-full mt-3 flex flex-wrap justify-center",
                },
                [
                  _c("vs-button", {
                    attrs: {
                      radius: "",
                      color: "primary",
                      type: "border",
                      "icon-pack": "feather",
                      icon: "icon-plus-circle",
                    },
                    on: { click: _vm.addServiceItem },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._l(_vm.service.services, function (item, i) {
            return _c("div", { key: i, staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col md:w-1/6 w-full mt-0" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { type: "number", min: "1", label: "Qtd." },
                    on: { change: _vm.calculateTotalValues },
                    model: {
                      value: item.quantity,
                      callback: function ($$v) {
                        _vm.$set(item, "quantity", $$v)
                      },
                      expression: "item.quantity",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/3 w-full mt-0" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v("Ítem/Taxa"),
                  ]),
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.initial",
                        value: { required: !item.id },
                        expression: "{ required: !item.id }",
                        modifiers: { initial: true },
                      },
                    ],
                    staticClass: "vue_select_drop_size_98",
                    attrs: {
                      reduce: (option) => option.value,
                      clearable: false,
                      options: _vm.serviceOptions,
                      placeholder: "Selecione",
                      dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      "data-vv-as": "Ítem|Taxa",
                      "data-vv-scope": "service",
                      name: "id" + i,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.changeItem(i)
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n            Nenhum resultado encontrado.\n          "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: item.id,
                      callback: function ($$v) {
                        _vm.$set(item, "id", $$v)
                      },
                      expression: "item.id",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("service.id" + i),
                          expression: "errors.has('service.id'+i)",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v("Campo obrigatório")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/6 w-full mt-0" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v("Valor Un."),
                  ]),
                  _c("currency-input", {
                    staticClass:
                      "w-full vs-inputx vs-input--input normal hasValue",
                    attrs: {
                      currency: { prefix: "R$ " },
                      valueAsInteger: false,
                      distractionFree: false,
                      precision: 2,
                      autoDecimalMode: true,
                      valueRange: { min: 0 },
                      allowNegative: false,
                    },
                    on: { change: _vm.calculateTotalValues },
                    model: {
                      value: item.value,
                      callback: function ($$v) {
                        _vm.$set(item, "value", $$v)
                      },
                      expression: "item.value",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/6 w-full mt-0" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v("Total"),
                  ]),
                  _c("br"),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        item.total
                          ? item.total.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })
                          : "R$ 0,00"
                      ) +
                      "\n        "
                  ),
                  _c("feather-icon", {
                    staticClass: "ml-4 cursor-pointer",
                    attrs: { icon: "Trash2Icon", svgClasses: "w-4 h-4" },
                    on: {
                      click: function ($event) {
                        return _vm.removeServiceItem(i)
                      },
                    },
                  }),
                ],
                1
              ),
            ])
          }),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupUpdateService = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      attrs: {
                        disabled:
                          !_vm.validateServiceForm || _vm.disableServiceSave,
                      },
                      on: { click: _vm.updateService },
                    },
                    [_vm._v("Salvar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
      _c("feather-icon", {
        attrs: {
          icon: "Edit3Icon",
          svgClasses: "h-5 w-5 mr-4 hover:text-primary cursor-pointer",
        },
        on: { click: _vm.editRecord },
      }),
      _c("feather-icon", {
        attrs: {
          icon: "Trash2Icon",
          svgClasses: "h-5 w-5 hover:text-danger cursor-pointer",
        },
        on: { click: _vm.confirmDeleteRecord },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }