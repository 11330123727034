<!-- =========================================================================================
    EXEMPLO DE ESTRUTURA DE ÁRVORE
    ----------------------------------------------------------------------------------------
      accountPlans: [
        {
          name: 'Node 1',
          id: 1,
          pid: 0,
          dragDisabled: true,
          addTreeNodeDisabled: true,
          addLeafNodeDisabled: true,
          editNodeDisabled: true,
          delNodeDisabled: true,
          children: [
            {
              name: 'Node 1-2',
              id: 2,
              isLeaf: true,
              pid: 1
            }
          ]
        },
        {
          name: 'Node 2',
          id: 3,
          pid: 0,
          disabled: true
        },
        {
          name: 'Node 3',
          id: 4,
          pid: 0
        }
      ]
========================================================================================== -->

<template>
  <div id="page-account-plans">
    <tree-search-tree v-if="ready" :account-plans-prop="accountPlans"></tree-search-tree>
  </div>
</template>

<script>
import TreeSearchTree from './TreeAccountPlans.vue'

// Store Module
import moduleAccountPlan from '@/store/account-plan/moduleAccountPlan.js'

export default {
  components: {
    TreeSearchTree
  },

  data () {
    return {
      ready: false
    }
  },

  computed: {
    accountPlans () {
      return this.$store.getters['accountPlan/forTree']
    }
  },

  async created () {
    if (!moduleAccountPlan.isRegistered) {
      // this.$store.registerModule('accountPlan', moduleAccountPlan)
      moduleAccountPlan.isRegistered = true
    }
    try {
      await this.$store.dispatch('accountPlan/fetchAll')
      this.ready = true
    } catch (error) {
      console.log(error)
    }
  }
}
</script>
