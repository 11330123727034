var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "configuration-email-tab" } },
    [
      _vm.ready
        ? _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "tabs-container px-6 pt-6",
                attrs: { slot: "no-body" },
                slot: "no-body",
              },
              [
                _c(
                  "vs-tabs",
                  { staticClass: "tab-action-btn-fill-conatiner" },
                  [
                    _c(
                      "vs-tab",
                      {
                        attrs: {
                          label: "Aniversário",
                          "icon-pack": "feather",
                          icon: "icon-gift",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tab-text" },
                          [
                            _c("birthday", {
                              staticClass: "mt-4",
                              attrs: {
                                mailConfig: _vm.mailConfig,
                                automaticEmails: _vm.automaticEmails,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "vs-tab",
                      {
                        attrs: {
                          label: "Financeiro",
                          "icon-pack": "feather",
                          icon: "icon-dollar-sign",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tab-text" },
                          [
                            _c("financial", {
                              staticClass: "mt-4",
                              attrs: {
                                mailConfig: _vm.mailConfig,
                                automaticEmails: _vm.automaticEmails,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "vs-tab",
                      {
                        attrs: {
                          label: "Agendas (exames e aulas)",
                          "icon-pack": "feather",
                          icon: "icon-calendar",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tab-text" },
                          [
                            _c("schedules", {
                              staticClass: "mt-4",
                              attrs: {
                                mailConfig: _vm.mailConfig,
                                automaticEmails: _vm.automaticEmails,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "vs-tab",
                      {
                        attrs: {
                          label: "Situação de CNH",
                          "icon-pack": "feather",
                          icon: "icon-credit-card",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tab-text" },
                          [
                            _c("cnh", {
                              staticClass: "mt-4",
                              attrs: {
                                mailConfig: _vm.mailConfig,
                                automaticEmails: _vm.automaticEmails,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }