<template>
  <div id="companyConfigurations-general-financial-tab">
    <vs-table noDataText="" :data="[]" class="bordered" hoverFlat style="overflow: -webkit-paged-y;">
      <template slot="thead">
        <vs-th style="font-size: 11px;"><span>Configuração</span></vs-th>
        <vs-th style="font-size: 11px;"><span>Ação</span></vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr style="font-size: 12px; opacity: 1 !important">
          <vs-td style="text-align:left">
            <span class="cursor-default font-semibold">Alertar ao sair do cadastro de alunos sem salvar. </span>
          </vs-td>
          <vs-td class="flex flex-wrap items-center">
            <vs-checkbox v-model="configs.general.studentSave" class="vs-checkbox-small ml-0 mr-1 mt-1"></vs-checkbox>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-divider></vs-divider>

    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="flex flex-wrap items-center justify-end">
          <vs-button @click="saveFinancial">Salvar</vs-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  computed: {
    companyConfigurations () {
      return this.$store.getters.companyConfigurations
    }
  },
  data () {
    return {
      configs: {
        type_configuration: 'BY_COMPANY',
        financial: { cashier: false },
        general: { studentSave: false }
      }
    }
  },
  methods: {
    async saveFinancial () {
      try {
        this.$vs.loading()
        await this.$http.post(`${process.env.VUE_APP_API_URL}/configuration`, this.configs)

        const company = JSON.parse(JSON.stringify(this.$store.state.AppActiveUser.company))
        company.configuration = JSON.parse(JSON.stringify(this.configs))
        this.$store.commit('UPDATE_USER_INFO', { company })

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Alterados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.log(error)
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    }
  },
  mounted () {
    this.configs = JSON.parse(JSON.stringify(this.companyConfigurations))
    this.configs.type_configuration = 'BY_COMPANY'
  }
}
</script>

<style>
  #companyConfigurations-general-financial-tab td {
    border-top: 1px solid #c0c0c0;
    line-height: 2;
  }
  #companyConfigurations-general-financial-tab .vs-con-table .vs-con-tbody {
    border: 1px solid #c0c0c0;
  }
  .tab-action-btn-fill-conatiner.con-vs-tabs .vs-tabs--content {
    padding: 0px 10px !important;
  }
  .vs-table--not-data {
    padding: 0px;
  }
</style>
