<template>
  <div id="tab-employee">
    <!-- <new-registration-modal :show="popupNewRegistration" title="Novo Modelo de Contrato"
      @action="refreshGrid(false)"
      @cancel="popupNewRegistration = false"></new-registration-modal> -->

    <!-- <edit-registration-modal :show="popupEditRegistration" title="Alterar Modelo de Contrato"
      @action="refreshInfiniteCacheGrid(false)"
      @cancel="popupEditRegistration = false"></edit-registration-modal> -->

    <div class="vx-card p-6">

      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ allData.total - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : allData.total }} de {{ allData.total }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Busca rápida por nome..." />
          <vs-button @click="handleNewRegistration" icon-pack="feather" icon="icon-user-plus" class="shadow-md mb-4 md:mb-0">Novo Modelo de Contrato</vs-button>
      </div>

      <div v-if="accordionDocumentTemplate" class="vx-row mt-0">
        <div class="vx-col md:w-full w-full mt-0">
          <div class="vx-row">
            <div class="vx-col md:w-3/4 w-full mt-0">
              <vs-input class="w-full mt-0" label="Título" v-model="contractModel.title" data-vv-as="Título" data-vv-scope="contractModel" v-validate.initial="'required'" name="title" />
              <span class="text-danger text-sm" v-show="errors.has('contractModel.title')">{{ errors.first('contractModel.title') }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <label class="vs-input--label">Cabeçalho</label>
              <v-select v-model="contractModel.print_header" :reduce="option => option.value" :clearable="false" :options="[{label: 'SIM', value: true}, {label: 'NÃO', value: false}]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"></v-select>
            </div>
          </div>

          <div class="vx-row mt-4">
            <div class="vx-col md:w-3/4 w-full mt-0">
              <div>
                <ckeditor :editor="editor" v-model="contractModel.body" :config="editorConfig" @ready="onReady"></ckeditor>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <div class="mt-0 flex flex-wrap items-center justify-end">
                      <vs-button class="ml-auto mt-4" color="secondary" @click="accordionDocumentTemplate = false">Cancelar</vs-button>
                      <vs-button class="ml-4 mt-4" @click="addContractModel" :disabled="!validateContractFormModel">Salvar Modelo De Contrato</vs-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <vs-divider position="left"><strong class="text-sm">Variáveis do Aluno</strong></vs-divider>
              <p v-for="(variable, i) in studentVariables" :key="`${i}_studentVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
                  <vx-tooltip :text="variable.variable" position="left">
                  <span class="under mb-1">{{variable.label}}</span>
                  </vx-tooltip>
              </p>
              <vs-divider position="left"><strong class="text-sm">Endereço</strong></vs-divider>
              <p v-for="(variable, i) in addressVariables" :key="`${i}_addressVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
                  <vx-tooltip :text="variable.variable" position="left">
                  <span class="under mb-1">{{variable.label}}</span>
                  </vx-tooltip>
              </p>
              <vs-divider position="left"><strong class="text-sm">Avalista</strong></vs-divider>
              <p v-for="(variable, i) in tenantVariables" :key="`${i}_tenantVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
                  <vx-tooltip :text="variable.variable" position="left">
                  <span class="under mb-1">{{variable.label}}</span>
                  </vx-tooltip>
              </p>
              <vs-divider position="left"><strong class="text-sm">Contrato</strong></vs-divider>
              <p v-for="(variable, i) in contractVariables" :key="`${i}_contractVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
                {{variable.label}}
              </p>
            </div>
          </div>

        </div>
      </div>


      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :overlayLoadingTemplate="overlayLoadingTemplate"
        :overlayNoRowsTemplate="noRowsTemplate"
        :localeText="localeText"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :cacheBlockSize="payload.limit"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination :total="totalPages" :max="10" v-model="currentPage" />

    </div>
  </div>
</template>

<script>
import _ from 'lodash'
// const newRegistrationModal = () => import('./newRegistrationModal.vue')
// const editRegistrationModal = () => import('./editRegistrationModal.vue')

import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { localePt_Br } from '@/assets/agGridTranslate/locale.pt_Br'

// Cell Renderer
import CellRendererActions  from './cell-renderer/CellRendererActions.vue'

import moduleContractModel from '@/store/contract-model/moduleContractModel.js'

import CKEditor from '@ckeditor/ckeditor5-vue2'

// ⚠️ NOTE: We don't use @ckeditor/ckeditor5-build-classic any more!
// Since we're building CKEditor from source, we use the source version of ClassicEditor.
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import ClassicEditor from '@/util/ckeditor5/src/ckeditor'
import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br'

import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat.js'
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote.js'
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js'
import Code from '@ckeditor/ckeditor5-basic-styles/src/code.js'
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock.js'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js'
import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport.js'
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js'
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed.js'
import Image from '@ckeditor/ckeditor5-image/src/image.js'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption.js'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle.js'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar.js'
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload.js'
import Indent from '@ckeditor/ckeditor5-indent/src/indent.js'
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js'
import Link from '@ckeditor/ckeditor5-link/src/link.js'
import List from '@ckeditor/ckeditor5-list/src/list.js'
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed.js'
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js'
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice.js'
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting.js'
import Table from '@ckeditor/ckeditor5-table/src/table.js'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar.js'
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation.js'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment.js'
import AutoImage from '@ckeditor/ckeditor5-image/src/autoimage.js'
import CKFinderUploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter.js'
import CloudServices from '@ckeditor/ckeditor5-cloud-services/src/cloudservices.js'
import FindAndReplace from '@ckeditor/ckeditor5-find-and-replace/src/findandreplace.js'
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js'
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor.js'
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily.js'
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize.js'
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight.js'
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize.js'
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock.js'
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat.js'
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters.js'
import SpecialCharactersArrows from '@ckeditor/ckeditor5-special-characters/src/specialcharactersarrows.js'
import SpecialCharactersCurrency from '@ckeditor/ckeditor5-special-characters/src/specialcharacterscurrency.js'
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials.js'
import SpecialCharactersLatin from '@ckeditor/ckeditor5-special-characters/src/specialcharacterslatin.js'
import SpecialCharactersMathematical from '@ckeditor/ckeditor5-special-characters/src/specialcharactersmathematical.js'
import SpecialCharactersText from '@ckeditor/ckeditor5-special-characters/src/specialcharacterstext.js'
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption.js'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline.js'
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount.js'

const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
import PaginationMixin from '@/mixins/pagination'

export default {
  mixins: [PaginationMixin],
  components: {
    AgGridVue,
    CellRendererActions,
    vSelect,
    ckeditor: CKEditor.component
    // newRegistrationModal,
    // editRegistrationModal
  },
  data () {
    return {
      // popupNewRegistration: false,
      // popupEditRegistration: false,
      accordionDocumentTemplate: false,
      contractModel: {
        id: null,
        title: null,
        print_header: false,
        body: ''
      },
      ckEd: null,
      editor: ClassicEditor,
      editorConfig: {
        language: 'pt-br',
        plugins: [
          Autoformat,
          BlockQuote,
          Bold,
          Code,
          CodeBlock,
          Essentials,
          GeneralHtmlSupport,
          Heading,
          HtmlEmbed,
          Image,
          ImageCaption,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          Indent,
          Italic,
          Link,
          List,
          MediaEmbed,
          Paragraph,
          PasteFromOffice,
          SourceEditing,
          Table,
          TableToolbar,
          TextTransformation,
          Alignment,
          AutoImage,
          CKFinderUploadAdapter,
          CloudServices,
          FindAndReplace,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          FontSize,
          Highlight,
          ImageResize,
          IndentBlock,
          RemoveFormat,
          SpecialCharacters,
          SpecialCharactersArrows,
          SpecialCharactersCurrency,
          SpecialCharactersEssentials,
          SpecialCharactersLatin,
          SpecialCharactersMathematical,
          SpecialCharactersText,
          TableCaption,
          TableCellProperties,
          TableProperties,
          Underline,
          WordCount
        ],
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            // 'link',
            // 'bulletedList',
            // 'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            // 'imageUpload',
            'blockQuote',
            'insertTable',
            // 'mediaEmbed',
            'undo',
            'redo',
            'alignment',
            'code',
            'findAndReplace',
            // 'fontBackgroundColor',
            // 'fontColor',
            'fontFamily',
            'fontSize',
            'highlight',
            'htmlEmbed',
            'removeFormat',
            'sourceEditing'
            // 'specialCharacters'
          ]
        },
        image: {
          toolbar: [
            'imageTextAlternative',
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side'
          ]
        },
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells'
            // 'tableCellProperties',
            // 'tableProperties'
          ]
        },
        htmlEmbed: {
          showPreviews: true
          // sanitizeHtml: (inputHtml) => {
          //   // Strip unsafe elements and attributes, e.g.:
          //   // the `<script>` elements and `on*` attributes.
          //   const outputHtml = sanitize(inputHtml)

          //   return {
          //     html: outputHtml,
          //     // true or false depending on whether the sanitizer stripped anything.
          //     hasChanged: true
          //   }
          // }
        },
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Paragraph',
              class: 'ck-heading_paragraph'
            },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Heading 1',
              class: 'ck-heading_heading1'
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Heading 2',
              class: 'ck-heading_heading2'
            }
          ]
        }
      },

      // Variáveis do aluno
      studentVariables: [
        {variable: '[logo_empresa]', label: 'Logo da empresa'},
        {variable: '[dia]', label: 'Dia'},
        {variable: '[mes]', label: 'Mês'},
        {variable: '[ano]', label: 'Ano'},
        {variable: '[nome_aluno]', label: 'Nome'},
        {variable: '[social_name]', label: 'Nome social'},
        {variable: '[data_cadastro]', label: 'Data de cadastro'},
        {variable: '[renach]', label: 'Nº do formulário RENACH'},
        {variable: '[cat_atual]', label: 'Categoria atual'},
        {variable: '[cat_pret]', label: 'Categoria pretendida'},
        {variable: '[data_nascimento]', label: 'Data de nascimento'},
        {variable: '[idade]', label: 'Idade'},
        {variable: '[nome_mae]', label: 'Nome da mãe'},
        {variable: '[nome_pai]', label: 'Nome do pai'},
        {variable: '[uf_nascimento]', label: 'Estado de nascimento'},
        {variable: '[cidade_nascimento]', label: 'Cidade de nascimento'},
        {variable: '[cpf_aluno]', label: 'CPF do aluno'},
        {variable: '[rg_aluno]', label: 'RG do aluno'},
        {variable: '[orgao_emissor]', label: 'Órgão emissor RG'},
        {variable: '[uf_rg]', label: 'Estado do RG'},
        {variable: '[nacionalidade_aluno]', label: 'Nacionalidade'},
        {variable: '[genero_aluno]', label: 'Gênero do aluno'},
        {variable: '[grau_instrucao]', label: 'Grau de instrução'},
        {variable: '[telefone_fixo]', label: 'Telefone fixo'},
        {variable: '[celular]', label: 'Celular'},
        {variable: '[email_aluno]', label: 'E-mail'},
        {variable: '[profissao_aluno]', label: 'Profissão'},
        {variable: '[estado_civil_aluno]', label: 'Estado civil'},
        {variable: '[extra_field]', label: 'Campo extra'}
      ],

      // ENDEREÇO:
      addressVariables: [
        {variable: '[logo_empresa]', label: 'Logo da empresa'},
        {variable: '[estado_aluno]', label: 'Estado'},
        {variable: '[cidade_aluno]', label: 'Cidade'},
        {variable: '[rua_aluno]', label: 'Rua'},
        {variable: '[numero_endereco]', label: 'Número'},
        {variable: '[bairro_aluno]', label: 'Bairro'},
        {variable: '[cep_aluno]', label: 'CEP'},
        {variable: '[complemento_endereco]', label: 'Complemento'}
      ],

      // AVALISTA:
      tenantVariables: [
        {variable: '[nome_avalista]', label: 'Nome avalista'},
        {variable: '[cpf_avalista]', label: 'CPF avalista'},
        {variable: '[rg_avalista]', label: 'RG avalista'},
        {variable: '[orgao_emissor_rg_avalista]', label: 'Órg. emissor RG avalista'},
        {variable: '[uf_rg_avalista]', label: 'UF RG avalista'},
        {variable: '[local_trabalho_avalista]', label: 'Local trabalho avalista'},
        {variable: '[conjuge_avalista]', label: 'Cônjuge avalista'},
        {variable: '[estado_avalista]', label: 'Estado'},
        {variable: '[cidade_avalista]', label: 'Cidade'},
        {variable: '[rua_avalista]', label: 'Rua'},
        {variable: '[numero_endereco_avalista]', label: 'Número end.'},
        {variable: '[bairro_avalista]', label: 'Bairro'},
        {variable: '[cep_avalista]', label: 'CEP'},
        {variable: '[complemento_endereco_avalista]', label: 'Complemento end.'}
      ],

      // CONTRATO:
      contractVariables: [
        {variable: '[valor_total]', label: 'Valor total do contrato'},
        {variable: '[valor_das_parcelas]', label: 'Valor de cada parcela'},
        {variable: '[valor_da_primeira_parcela]', label: 'Valor da primeira parcela/entrada'},
        {variable: '[numero_total_de_parcelas]', label: 'Número de parcelas'},
        {variable: '[vencimento_de_parcelas]', label: 'Vencimento de parcelas'},
        {variable: '[nome_do_servico]', label: 'Nome do serviço'},
        {variable: '[pacote_detalhado]', label: 'Pacote detalhado'},
        {variable: '[multa_do_contrato]', label: 'Multa do contrato'},
        {variable: '[tipo_de_pagamento]', label: 'Tipo de pagamento da 1a Parcela'},
        {variable: '[juros_do_contrato]', label: 'Juros do contrato'},

        {variable: '[DATA_VENCIMENTO_1PARCELA]', label: 'Vencimento 1ª Parcela'},
        {variable: '[DATA_VENCIMENTO_2PARCELA]', label: 'Vencimento 2ª Parcela'},
        {variable: '[DATA_VENCIMENTO_3PARCELA]', label: 'Vencimento 3ª Parcela'},
        {variable: '[DATA_VENCIMENTO_4PARCELA]', label: 'Vencimento 4ª Parcela'},
        {variable: '[DATA_VENCIMENTO_5PARCELA]', label: 'Vencimento 5ª Parcela'},
        {variable: '[DATA_VENCIMENTO_6PARCELA]', label: 'Vencimento 6ª Parcela'},
        {variable: '[DATA_VENCIMENTO_7PARCELA]', label: 'Vencimento 7ª Parcela'},
        {variable: '[DATA_VENCIMENTO_8PARCELA]', label: 'Vencimento 8ª Parcela'},
        {variable: '[DATA_VENCIMENTO_9PARCELA]', label: 'Vencimento 9ª Parcela'},
        {variable: '[DATA_VENCIMENTO_10PARCELA]', label: 'Vencimento 10ª Parcela'},
        {variable: '[DATA_VENCIMENTO_11PARCELA]', label: 'Vencimento 11ª Parcela'},
        {variable: '[DATA_VENCIMENTO_12PARCELA]', label: 'Vencimento 12ª Parcela'},
        {variable: '[DATA_VENCIMENTO_13PARCELA]', label: 'Vencimento 13ª Parcela'},
        {variable: '[DATA_VENCIMENTO_14PARCELA]', label: 'Vencimento 14ª Parcela'},
        {variable: '[DATA_VENCIMENTO_15PARCELA]', label: 'Vencimento 15ª Parcela'},
        {variable: '[DATA_VENCIMENTO_16PARCELA]', label: 'Vencimento 16ª Parcela'},
        {variable: '[DATA_VENCIMENTO_17PARCELA]', label: 'Vencimento 17ª Parcela'},
        {variable: '[DATA_VENCIMENTO_18PARCELA]', label: 'Vencimento 18ª Parcela'},
        {variable: '[DATA_VENCIMENTO_19PARCELA]', label: 'Vencimento 19ª Parcela'},
        {variable: '[DATA_VENCIMENTO_20PARCELA]', label: 'Vencimento 20ª Parcela'},
        {variable: '[DATA_VENCIMENTO_21PARCELA]', label: 'Vencimento 21ª Parcela'},
        {variable: '[DATA_VENCIMENTO_22PARCELA]', label: 'Vencimento 22ª Parcela'},
        {variable: '[DATA_VENCIMENTO_23PARCELA]', label: 'Vencimento 23ª Parcela'},
        {variable: '[DATA_VENCIMENTO_24PARCELA]', label: 'Vencimento 24ª Parcela'},


        {variable: '[VALOR_1PARCELA]', label: 'Valor 1ª Parcela'},
        {variable: '[VALOR_2PARCELA]', label: 'Valor 2ª Parcela'},
        {variable: '[VALOR_3PARCELA]', label: 'Valor 3ª Parcela'},
        {variable: '[VALOR_4PARCELA]', label: 'Valor 4ª Parcela'},
        {variable: '[VALOR_5PARCELA]', label: 'Valor 5ª Parcela'},
        {variable: '[VALOR_6PARCELA]', label: 'Valor 6ª Parcela'},
        {variable: '[VALOR_7PARCELA]', label: 'Valor 7ª Parcela'},
        {variable: '[VALOR_8PARCELA]', label: 'Valor 8ª Parcela'},
        {variable: '[VALOR_9PARCELA]', label: 'Valor 9ª Parcela'},
        {variable: '[VALOR_10PARCELA]', label: 'Valor 10ª Parcela'},
        {variable: '[VALOR_11PARCELA]', label: 'Valor 11ª Parcela'},
        {variable: '[VALOR_12PARCELA]', label: 'Valor 12ª Parcela'},
        {variable: '[VALOR_13PARCELA]', label: 'Valor 13ª Parcela'},
        {variable: '[VALOR_14PARCELA]', label: 'Valor 14ª Parcela'},
        {variable: '[VALOR_15PARCELA]', label: 'Valor 15ª Parcela'},
        {variable: '[VALOR_16PARCELA]', label: 'Valor 16ª Parcela'},
        {variable: '[VALOR_17PARCELA]', label: 'Valor 17ª Parcela'},
        {variable: '[VALOR_18PARCELA]', label: 'Valor 18ª Parcela'},
        {variable: '[VALOR_19PARCELA]', label: 'Valor 19ª Parcela'},
        {variable: '[VALOR_20PARCELA]', label: 'Valor 20ª Parcela'},
        {variable: '[VALOR_21PARCELA]', label: 'Valor 21ª Parcela'},
        {variable: '[VALOR_22PARCELA]', label: 'Valor 22ª Parcela'},
        {variable: '[VALOR_23PARCELA]', label: 'Valor 23ª Parcela'},
        {variable: '[VALOR_24PARCELA]', label: 'Valor 24ª Parcela'},

        {variable: '[TIPO_PAGAMENTO_1PARCELA]', label: 'Tipo Pagamento 1ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_2PARCELA]', label: 'Tipo Pagamento 2ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_3PARCELA]', label: 'Tipo Pagamento 3ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_4PARCELA]', label: 'Tipo Pagamento 4ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_5PARCELA]', label: 'Tipo Pagamento 5ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_6PARCELA]', label: 'Tipo Pagamento 6ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_7PARCELA]', label: 'Tipo Pagamento 7ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_8PARCELA]', label: 'Tipo Pagamento 8ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_9PARCELA]', label: 'Tipo Pagamento 9ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_10PARCELA]', label: 'Tipo Pagamento 10ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_11PARCELA]', label: 'Tipo Pagamento 11ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_12PARCELA]', label: 'Tipo Pagamento 12ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_13PARCELA]', label: 'Tipo Pagamento 13ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_14PARCELA]', label: 'Tipo Pagamento 14ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_15PARCELA]', label: 'Tipo Pagamento 15ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_16PARCELA]', label: 'Tipo Pagamento 16ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_17PARCELA]', label: 'Tipo Pagamento 17ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_18PARCELA]', label: 'Tipo Pagamento 18ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_19PARCELA]', label: 'Tipo Pagamento 19ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_20PARCELA]', label: 'Tipo Pagamento 20ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_21PARCELA]', label: 'Tipo Pagamento 21ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_22PARCELA]', label: 'Tipo Pagamento 22ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_23PARCELA]', label: 'Tipo Pagamento 23ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_24PARCELA]', label: 'Tipo Pagamento 24ª Parcela'}

      ],

      searchQuery: '',

      payload: {
        offset: 0,
        limit: 40,
        searchQuery: null,
        params: null,
        consultApi: true
      },

      // AgGrid
      localeText: null,
      gridApi: null,
      dataSource: {},
      gridOptions: {
        rowModelType: 'infinite'
      },
      overlayLoadingTemplate: 'Consultando dados...',
      noRowsTemplate: 'Nenhum dado foi encontrado',
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'Título',
          field: 'title',
          width: 250
        },
        {
          headerName: 'Ações',
          field: 'actions',
          width: 95,
          sortable: false,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            edit: this.edit, // usado para passar parametros para dentro o componente renderizado no ag grid
            delete: this.confirmDelete // usado para passar parametros para dentro o componente renderizado no ag grid
          }
        }
      ],
      components: {
        CellRendererActions
      }
    }
  },
  computed: {
    validateContractFormModel () {
      return !this.errors.any('contractModel')
    },
    // contractModel () {
    //   return this.$store.state.contractModel.contractModel
    // },
    allData () {
      return this.$store.state.contractModel.contractsGrid
    }
  },
  methods: {
    reset() {
      this.contractModel = {
        id: null,
        title: null,
        print_header: false,
        body: ''
      }
    },
    edit (data) {
      // this.$store.commit('contractModel/SET', data.data)
      this.contractModel = JSON.parse(JSON.stringify(data.data))
      this.contractModel.print_header = !!this.contractModel.print_header

      // this.popupEditRegistration = true
      this.accordionDocumentTemplate = true

    },
    confirmDelete (data) {
      this.$store.commit('contractModel/SET', data.data)
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: `Tem certeza que deseja excluir o contrato \n "${data.data.title}"?`,
        accept: () => this.delete(data.data.id),
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    delete (id) {
      this.$store.dispatch('contractModel/delete', id)
        .then(()   => {
          this.refreshGrid()
          this.showDeleteSuccess()
        })
        .catch(err => { console.error(err) })
        .finally(() => this.reset())
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'SUCESSO',
        text: 'Dados Excluídos',
        iconPack: 'feather',
        icon: 'icon-check'
      })
    },

    refreshGrid (consultApi = false) {
      this.payload.consultApi = consultApi
      this.gridApi.setDatasource(this.dataSource)
      // this.gridApi.refreshInfiniteCache() // REMOVI ESSE PQ QDO NÃO HÁ NENHUM REGISTRO NÃO ATUALIZA AO CADASTRAR O PRIMEIRO
    },
    refreshInfiniteCacheGrid (consultApi = false) {
      this.payload.consultApi = consultApi
      this.gridApi.refreshInfiniteCache()
    },

    handleNewRegistration () {
      // this.popupNewRegistration = true
      this.contractModel = { id: null, title: null, print_header: false, body: '' }
      this.accordionDocumentTemplate = true
    },
    onReady (editor)  {
      this.ckEd = editor
      // console.log(editor.ui.componentFactory.names())
    },
    copyVariable (variable) {
      //INSERE A VARIÁVEL ONDE O CURSOR ESTIVER
      this.ckEd.model.change(writer => {
        const insertPosition = this.ckEd.model.document.selection.getFirstPosition()
        writer.insertText(variable, {}, insertPosition)
      })

      //COPIA A VARIÁVEL PARA A MEMÓRIA (CTRL C + CTRL V)
      const thisIns = this
      this.$copyText(variable).then(function () {
        thisIns.$vs.notify({
          time: 700,
          title: 'Success',
          text: 'Variável copiada.',
          color: 'success',
          iconPack: 'feather',
          position: 'top-center',
          icon: 'icon-check-circle'
        })
      }, function () {
        thisIns.$vs.notify({
          title: 'Failed',
          text: 'Oops. Algo inesperado aconteceu. Por favor avise o suporte',
          color: 'danger',
          iconPack: 'feather',
          position: 'top-center',
          icon: 'icon-alert-circle'
        })
      })
    },

    async addContractModel () {
      this.$vs.loading()
      this.disableContractModelSave = true
      setTimeout(() => { this.disableContractModelSave = false }, 2000) //TRAVA O BOTÃO SALVAR POR 3 SEGUNDOS

      try {
        if (this.contractModel.id) await this.$store.dispatch('contractModel/update', this.contractModel)
        else await this.$store.dispatch('contractModel/store', this.contractModel)

        this.$vs.loading.close()
        this.refreshGrid(false)
        // this.popupContractModel = false
        this.accordionDocumentTemplate = false
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$nextTick(() => {
          this.$vs.loading.close()
        })
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

    getSearchResult (searchVal) {
      this.payload.searchQuery = searchVal
      this.gridApi.setDatasource(this.dataSource)
    },
    updateSearchQuery (val) {
      this.debouncedGetSearchResult(val)
    },
    onGridReady (params) {
      this.gridApi = params.api
      const self = this
      this.dataSource = {
        rowCount: null,
        async getRows (params) {
          self.payload.offset = params.startRow
          self.payload.params = params

          if (self.payload.consultApi) await self.getAllData()
          self.payload.consultApi = true //esta linha faz com que a próxima chamada sempre seja feita para a API

          let lastRow = -1
          if (self.allData.total <= params.endRow) {
            lastRow = self.allData.total
          }
          params.successCallback(self.allData.data, lastRow)
        }
      }
      this.gridApi.setDatasource(this.dataSource)
    },

    async getAllData () {
      this.gridApi.showLoadingOverlay()
      const data = await this.$store.dispatch('contractModel/fetchGrid', this.payload)
      this.gridApi.hideOverlay()
      if (!data.data.total) {
        this.gridApi.showNoRowsOverlay()
      }
      return data
    }
  },
  mounted () {
    this.localeText = localePt_Br
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created () {
    this.setGrid('allData')
    this.debouncedGetSearchResult = _.debounce(this.getSearchResult, 600)

    if (!moduleContractModel.isRegistered) {
      this.$store.registerModule('contractModel', moduleContractModel)
      moduleContractModel.isRegistered = true
    }
  }
}
</script>

<style>
  .ck-editor__editable {
    min-height: 650px;
  }
</style>
