<template>
  <div id="configurations-tabs">
    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Dados do CFC" icon-pack="feather" icon="icon-info">
            <div class="tab-text">
              <cfc-data class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Geral" icon-pack="feather" icon="icon-sliders">
            <div class="tab-text">
              <general class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="E-mail Automático" icon-pack="feather" icon="icon-mail">
            <div class="tab-text">
              <automatic-email class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Horários" icon-pack="feather" icon="icon-calendar">
            <div class="tab-text">
              <schedule class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Planos de Contas" icon-pack="feather" icon="icon-dollar-sign">
            <div class="tab-text">
              <account-plan class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Serviços" icon-pack="feather" icon="icon-clipboard">
            <div class="tab-text">
              <service class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Segurança" icon-pack="feather" icon="icon-lock">
            <div class="tab-text">
              <security class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Plano SIGCFC" icon-pack="feather" icon="icon-server">
            <div class="tab-text">
              <sigcfc-plan class="mt-4" />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import CfcData        from './tabs/CfcData/CfcData.vue'
import General        from './tabs/General/General.vue'
import AutomaticEmail from './tabs/AutomaticEmail/AutomaticEmail.vue'
import Schedule       from './tabs/Schedule/Schedule.vue'
import AccountPlan    from './tabs/AccountPlan/AccountPlan.vue'
import Service        from './tabs/Service/Service.vue'
import Security       from './tabs/Security/Security.vue'
import SigcfcPlan     from './tabs/SigcfcPlan/SigcfcPlan.vue'

export default {
  components: {
    CfcData,
    General,
    AutomaticEmail,
    Schedule,
    AccountPlan,
    Service,
    Security,
    SigcfcPlan
  },
  data () {
    return {
      vehicle: {},
      ready: false,
      activeTab: 0
    }
  }
}
</script>

<style lang="scss">
#configurations-tabs {
  .vs-tabs--content {
    padding: 0 10px 23px !important;
  }
}
</style>
