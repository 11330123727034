var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "configurations-tabs" } },
    [
      _c("vx-card", [
        _c(
          "div",
          {
            staticClass: "tabs-container px-6 pt-6",
            attrs: { slot: "no-body" },
            slot: "no-body",
          },
          [
            _c(
              "vs-tabs",
              {
                staticClass: "tab-action-btn-fill-conatiner",
                model: {
                  value: _vm.activeTab,
                  callback: function ($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab",
                },
              },
              [
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "Dados do CFC",
                      "icon-pack": "feather",
                      icon: "icon-info",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [_c("cfc-data", { staticClass: "mt-4" })],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "Geral",
                      "icon-pack": "feather",
                      icon: "icon-sliders",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [_c("general", { staticClass: "mt-4" })],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "E-mail Automático",
                      "icon-pack": "feather",
                      icon: "icon-mail",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [_c("automatic-email", { staticClass: "mt-4" })],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "Horários",
                      "icon-pack": "feather",
                      icon: "icon-calendar",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [_c("schedule", { staticClass: "mt-4" })],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "Planos de Contas",
                      "icon-pack": "feather",
                      icon: "icon-dollar-sign",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [_c("account-plan", { staticClass: "mt-4" })],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "Serviços",
                      "icon-pack": "feather",
                      icon: "icon-clipboard",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [_c("service", { staticClass: "mt-4" })],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "Segurança",
                      "icon-pack": "feather",
                      icon: "icon-lock",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [_c("security", { staticClass: "mt-4" })],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "Plano SIGCFC",
                      "icon-pack": "feather",
                      icon: "icon-server",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [_c("sigcfc-plan", { staticClass: "mt-4" })],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }