var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "tab-employee" } }, [
    _c(
      "div",
      { staticClass: "vx-card p-6" },
      [
        _c(
          "div",
          { staticClass: "flex flex-wrap items-center" },
          [
            _c(
              "div",
              { staticClass: "flex-grow" },
              [
                _c(
                  "vs-dropdown",
                  {
                    staticClass: "cursor-pointer",
                    attrs: { "vs-trigger-click": "" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium",
                      },
                      [
                        _c("span", { staticClass: "mr-2" }, [
                          _vm._v(
                            _vm._s(
                              _vm.currentPage * _vm.paginationPageSize -
                                (_vm.paginationPageSize - 1)
                            ) +
                              " - " +
                              _vm._s(
                                _vm.allData.total -
                                  _vm.currentPage * _vm.paginationPageSize >
                                  0
                                  ? _vm.currentPage * _vm.paginationPageSize
                                  : _vm.allData.total
                              ) +
                              " de " +
                              _vm._s(_vm.allData.total)
                          ),
                        ]),
                        _c("feather-icon", {
                          attrs: {
                            icon: "ChevronDownIcon",
                            svgClasses: "h-4 w-4",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "vs-dropdown-menu",
                      [
                        _c(
                          "vs-dropdown-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.paginationSetPageSize(10)
                              },
                            },
                          },
                          [_c("span", [_vm._v("10")])]
                        ),
                        _c(
                          "vs-dropdown-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.paginationSetPageSize(20)
                              },
                            },
                          },
                          [_c("span", [_vm._v("20")])]
                        ),
                        _c(
                          "vs-dropdown-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.paginationSetPageSize(30)
                              },
                            },
                          },
                          [_c("span", [_vm._v("30")])]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("vs-input", {
              staticClass:
                "sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4",
              attrs: { placeholder: "Busca rápida por nome..." },
              on: { input: _vm.updateSearchQuery },
              model: {
                value: _vm.searchQuery,
                callback: function ($$v) {
                  _vm.searchQuery = $$v
                },
                expression: "searchQuery",
              },
            }),
            _c(
              "vs-button",
              {
                staticClass: "shadow-md mb-4 md:mb-0",
                attrs: { "icon-pack": "feather", icon: "icon-user-plus" },
                on: { click: _vm.handleNewRegistration },
              },
              [_vm._v("Novo Modelo de Contrato")]
            ),
          ],
          1
        ),
        _vm.accordionDocumentTemplate
          ? _c("div", { staticClass: "vx-row mt-0" }, [
              _c("div", { staticClass: "vx-col md:w-full w-full mt-0" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-3/4 w-full mt-0" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.initial",
                            value: "required",
                            expression: "'required'",
                            modifiers: { initial: true },
                          },
                        ],
                        staticClass: "w-full mt-0",
                        attrs: {
                          label: "Título",
                          "data-vv-as": "Título",
                          "data-vv-scope": "contractModel",
                          name: "title",
                        },
                        model: {
                          value: _vm.contractModel.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.contractModel, "title", $$v)
                          },
                          expression: "contractModel.title",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("contractModel.title"),
                              expression: "errors.has('contractModel.title')",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.errors.first("contractModel.title"))
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v("Cabeçalho"),
                      ]),
                      _c("v-select", {
                        attrs: {
                          reduce: (option) => option.value,
                          clearable: false,
                          options: [
                            { label: "SIM", value: true },
                            { label: "NÃO", value: false },
                          ],
                          placeholder: "Selecione",
                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                        },
                        model: {
                          value: _vm.contractModel.print_header,
                          callback: function ($$v) {
                            _vm.$set(_vm.contractModel, "print_header", $$v)
                          },
                          expression: "contractModel.print_header",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "vx-row mt-4" }, [
                  _c("div", { staticClass: "vx-col md:w-3/4 w-full mt-0" }, [
                    _c(
                      "div",
                      [
                        _c("ckeditor", {
                          attrs: {
                            editor: _vm.editor,
                            config: _vm.editorConfig,
                          },
                          on: { ready: _vm.onReady },
                          model: {
                            value: _vm.contractModel.body,
                            callback: function ($$v) {
                              _vm.$set(_vm.contractModel, "body", $$v)
                            },
                            expression: "contractModel.body",
                          },
                        }),
                        _c("div", { staticClass: "vx-row" }, [
                          _c("div", { staticClass: "vx-col w-full" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mt-0 flex flex-wrap items-center justify-end",
                              },
                              [
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "ml-auto mt-4",
                                    attrs: { color: "secondary" },
                                    on: {
                                      click: function ($event) {
                                        _vm.accordionDocumentTemplate = false
                                      },
                                    },
                                  },
                                  [_vm._v("Cancelar")]
                                ),
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "ml-4 mt-4",
                                    attrs: {
                                      disabled: !_vm.validateContractFormModel,
                                    },
                                    on: { click: _vm.addContractModel },
                                  },
                                  [_vm._v("Salvar Modelo De Contrato")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                    [
                      _c("vs-divider", { attrs: { position: "left" } }, [
                        _c("strong", { staticClass: "text-sm" }, [
                          _vm._v("Variáveis do Aluno"),
                        ]),
                      ]),
                      _vm._l(_vm.studentVariables, function (variable, i) {
                        return _c(
                          "p",
                          {
                            key: `${i}_studentVariables`,
                            staticClass: "text-sm cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.copyVariable(variable.variable)
                              },
                            },
                          },
                          [
                            _c(
                              "vx-tooltip",
                              {
                                attrs: {
                                  text: variable.variable,
                                  position: "left",
                                },
                              },
                              [
                                _c("span", { staticClass: "under mb-1" }, [
                                  _vm._v(_vm._s(variable.label)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      _c("vs-divider", { attrs: { position: "left" } }, [
                        _c("strong", { staticClass: "text-sm" }, [
                          _vm._v("Endereço"),
                        ]),
                      ]),
                      _vm._l(_vm.addressVariables, function (variable, i) {
                        return _c(
                          "p",
                          {
                            key: `${i}_addressVariables`,
                            staticClass: "text-sm cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.copyVariable(variable.variable)
                              },
                            },
                          },
                          [
                            _c(
                              "vx-tooltip",
                              {
                                attrs: {
                                  text: variable.variable,
                                  position: "left",
                                },
                              },
                              [
                                _c("span", { staticClass: "under mb-1" }, [
                                  _vm._v(_vm._s(variable.label)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      _c("vs-divider", { attrs: { position: "left" } }, [
                        _c("strong", { staticClass: "text-sm" }, [
                          _vm._v("Avalista"),
                        ]),
                      ]),
                      _vm._l(_vm.tenantVariables, function (variable, i) {
                        return _c(
                          "p",
                          {
                            key: `${i}_tenantVariables`,
                            staticClass: "text-sm cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.copyVariable(variable.variable)
                              },
                            },
                          },
                          [
                            _c(
                              "vx-tooltip",
                              {
                                attrs: {
                                  text: variable.variable,
                                  position: "left",
                                },
                              },
                              [
                                _c("span", { staticClass: "under mb-1" }, [
                                  _vm._v(_vm._s(variable.label)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      _c("vs-divider", { attrs: { position: "left" } }, [
                        _c("strong", { staticClass: "text-sm" }, [
                          _vm._v("Contrato"),
                        ]),
                      ]),
                      _vm._l(_vm.contractVariables, function (variable, i) {
                        return _c(
                          "p",
                          {
                            key: `${i}_contractVariables`,
                            staticClass: "text-sm cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.copyVariable(variable.variable)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(variable.label) +
                                "\n            "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _c("ag-grid-vue", {
          ref: "agGridTable",
          staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
          attrs: {
            components: _vm.components,
            gridOptions: _vm.gridOptions,
            columnDefs: _vm.columnDefs,
            defaultColDef: _vm.defaultColDef,
            overlayLoadingTemplate: _vm.overlayLoadingTemplate,
            overlayNoRowsTemplate: _vm.noRowsTemplate,
            localeText: _vm.localeText,
            rowSelection: "multiple",
            colResizeDefault: "shift",
            animateRows: true,
            pagination: true,
            paginationPageSize: _vm.paginationPageSize,
            cacheBlockSize: _vm.payload.limit,
            suppressPaginationPanel: true,
            enableRtl: _vm.$vs.rtl,
          },
          on: { "grid-ready": _vm.onGridReady },
        }),
        _c("vs-pagination", {
          attrs: { total: _vm.totalPages, max: 10 },
          model: {
            value: _vm.currentPage,
            callback: function ($$v) {
              _vm.currentPage = $$v
            },
            expression: "currentPage",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }