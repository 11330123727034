var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "accountsDue-automatic-email-tab" } },
    [
      !_vm.mailConfig
        ? _c(
            "vs-alert",
            {
              staticClass: "mt-2",
              attrs: {
                color: "primary",
                title: "Orientações:",
                active: !_vm.mailConfig,
                "icon-pack": "feather",
              },
            },
            [
              _c("p", [
                _vm._v(
                  "Para enviar emails é necessário configurar sua conta de email."
                ),
              ]),
              _c("p", [
                _vm._v(
                  "Nesta mesma tela, acesse a aba Geral e dentro desta nova tela, acesse a aba E-mail."
                ),
              ]),
              _c("p", [
                _vm._v(
                  "Efetue as configurações e retorne aqui para configurar o envio automático de e-mails."
                ),
              ]),
            ]
          )
        : _c(
            "div",
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-full w-full" },
                  [
                    _c(
                      "vs-checkbox",
                      {
                        staticClass: "ml-0 mr-1 mt-1",
                        model: {
                          value: _vm.configAutoSend.accountsDue.status,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.configAutoSend.accountsDue,
                              "status",
                              $$v
                            )
                          },
                          expression: "configAutoSend.accountsDue.status",
                        },
                      },
                      [
                        _vm._v(
                          "Habilitar o envio automático para contas à vencer."
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-full w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      staticClass: "w-full mt-0",
                      attrs: {
                        label: "Assunto",
                        disabled: !_vm.configAutoSend.accountsDue.status,
                        "data-vv-scope": "accountsDue",
                        "data-vv-as": "Assunto",
                        name: "subject",
                      },
                      model: {
                        value: _vm.configAutoSend.accountsDue.subject,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.configAutoSend.accountsDue,
                            "subject",
                            $$v
                          )
                        },
                        expression: "configAutoSend.accountsDue.subject",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("accountsDue.subject"),
                            expression: "errors.has('accountsDue.subject')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("accountsDue.subject")))]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-3/4 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Enviar quantos dias antes do vencimento?"),
                    ]),
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      staticClass: "vue_select_drop_size_200",
                      attrs: {
                        appendToBody: "",
                        disabled: !_vm.configAutoSend.accountsDue.status,
                        "data-vv-scope": "accountsDue",
                        "data-vv-as": "Dias antes do vencimento",
                        name: "daysBefore",
                        multiple: "",
                        placeholder: "Selecione",
                        options: [
                          { value: "0", label: "No dia do vencimento" },
                          { value: "1", label: "1 dia antes do vencimento" },
                          { value: "2", label: "2 dias antes do vencimento" },
                          { value: "3", label: "3 dias antes do vencimento" },
                          { value: "4", label: "4 dias antes do vencimento" },
                          { value: "5", label: "5 dias antes do vencimento" },
                        ],
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n              Desculpe, nenhum resultado encontrado.\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.configAutoSend.accountsDue.daysBefore,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.configAutoSend.accountsDue,
                            "daysBefore",
                            $$v
                          )
                        },
                        expression: "configAutoSend.accountsDue.daysBefore",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("accountsDue.daysBefore"),
                            expression: "errors.has('accountsDue.daysBefore')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.errors.first("accountsDue.daysBefore"))
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Em que Horário?"),
                    ]),
                    _c("v-select", {
                      staticClass: "vue_select_drop_size_200",
                      attrs: {
                        appendToBody: "",
                        disabled: !_vm.configAutoSend.accountsDue.status,
                        placeholder: "Selecione",
                        reduce: (option) => option.value,
                        clearable: false,
                        options: [
                          { value: "06:00:00", label: "06:00h" },
                          { value: "21:30:00", label: "21:30h" },
                        ],
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n              Desculpe, nenhum resultado encontrado.\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.configAutoSend.accountsDue.time,
                        callback: function ($$v) {
                          _vm.$set(_vm.configAutoSend.accountsDue, "time", $$v)
                        },
                        expression: "configAutoSend.accountsDue.time",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("vs-divider"),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-2/5 w-full" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v(
                        "Digite o texto utilizando o termo [nome_aluno] onde desejar mostrar o mesmo."
                      ),
                    ]),
                    _c("vs-textarea", {
                      staticClass: "mb-0",
                      attrs: {
                        disabled: !_vm.configAutoSend.accountsDue.status,
                        height: "100px",
                        width: "100%",
                      },
                      model: {
                        value: _vm.configAutoSend.accountsDue.text,
                        callback: function ($$v) {
                          _vm.$set(_vm.configAutoSend.accountsDue, "text", $$v)
                        },
                        expression: "configAutoSend.accountsDue.text",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "vx-col md:w-3/5 w-full" }, [
                  _c("h2", { staticClass: "text-center" }, [_vm._v("Prévia")]),
                  _c(
                    "table",
                    {
                      staticClass: "head-wrap w320 full-width-gmail-android",
                      attrs: {
                        bgcolor: "#f9f8f8",
                        cellpadding: "0",
                        cellspacing: "0",
                        border: "0",
                        width: "100%",
                      },
                    },
                    [
                      _c("tr", [
                        _c(
                          "td",
                          {
                            attrs: {
                              background:
                                "http://app.sigcfc.com.br/assets/images/emailTemplate/stripes.png",
                              bgcolor: "#ffffff",
                              width: "100%",
                              height: "8",
                              valign: "top",
                            },
                          },
                          [_c("div", { attrs: { height: "8" } })]
                        ),
                      ]),
                      _c("tr", { staticClass: "header-background" }, [
                        _c(
                          "td",
                          {
                            staticClass: "header container",
                            attrs: { align: "center" },
                          },
                          [
                            _c("div", { staticClass: "content" }, [
                              _c("span", { staticClass: "brand" }, [
                                _c("a", { attrs: { href: "#" } }, [
                                  _vm._v(_vm._s(_vm.company.trading_name)),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c("table", { staticClass: "body-wrap w320" }, [
                    _c("tr", [
                      _c("td"),
                      _c("td", { staticClass: "container" }, [
                        _c("div", { staticClass: "content" }, [
                          _c("table", { attrs: { cellspacing: "0" } }, [
                            _c("tr", [
                              _c("td", [
                                _c("table", { staticClass: "body" }, [
                                  _c("tr", [
                                    _c("td", { staticClass: "body-padding" }),
                                    _c("td", { staticClass: "body-padded" }, [
                                      _c("div", { staticClass: "body-title" }, [
                                        _vm._v(
                                          "\n                                  " +
                                            _vm._s(
                                              _vm.configAutoSend.accountsDue
                                                .text
                                            ) +
                                            "\n                                  "
                                        ),
                                        _c("hr"),
                                        _vm._v(
                                          "\n                                  Dados da conta: "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                  Valor: R$ 0000,00 "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                  Vencimento: xx/xx/xxxx "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                  Forma de pagamento: xxxx x xxx xx "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                  Referente à: xxxx x xxx xx "
                                        ),
                                        _c("br"),
                                      ]),
                                    ]),
                                    _c("td", { staticClass: "body-padding" }),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("td"),
                    ]),
                  ]),
                  _c("hr"),
                  _vm.company.logo
                    ? _c(
                        "table",
                        {
                          staticClass:
                            "footer-wrap w320 full-width-gmail-android",
                          staticStyle: { width: "100%" },
                        },
                        [
                          _c("tr", [
                            _c("td", { attrs: { align: "center" } }, [
                              _c("img", {
                                attrs: {
                                  alt: _vm.company.trading_name,
                                  height: "90",
                                  src:
                                    "https://app-sigcfc-bucket.s3-us-west-2.amazonaws.com/companyLogo/" +
                                    _vm.company.logo.filename,
                                },
                              }),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
              _c("vs-divider"),
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap items-center justify-end" },
                    [
                      _c(
                        "vs-button",
                        {
                          attrs: { disabled: !_vm.validateForm },
                          on: {
                            click: function ($event) {
                              return _vm.save("accountsDue")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "Salvar configurações de avisos para contas à vencer"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }