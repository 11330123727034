<template>
  <div id="configuration-email-tab">
    <vx-card v-if="ready">
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs class="tab-action-btn-fill-conatiner">
          <vs-tab label="Aniversário" icon-pack="feather" icon="icon-gift">
            <div class="tab-text">
              <birthday class="mt-4" :mailConfig="mailConfig" :automaticEmails="automaticEmails" />
            </div>
          </vs-tab>
          <vs-tab label="Financeiro" icon-pack="feather" icon="icon-dollar-sign">
            <div class="tab-text">
              <financial class="mt-4" :mailConfig="mailConfig" :automaticEmails="automaticEmails" />
            </div>
          </vs-tab>
          <vs-tab label="Agendas (exames e aulas)" icon-pack="feather" icon="icon-calendar">
            <div class="tab-text">
              <schedules class="mt-4" :mailConfig="mailConfig" :automaticEmails="automaticEmails" />
            </div>
          </vs-tab>
          <vs-tab label="Situação de CNH" icon-pack="feather" icon="icon-credit-card">
            <div class="tab-text">
              <cnh class="mt-4" :mailConfig="mailConfig" :automaticEmails="automaticEmails" />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import Birthday   from './tabs/Birthday/Birthday.vue'
import Financial  from './tabs/Financial/Financial.vue'
import Schedules  from './tabs/Schedules/Schedules.vue'
import Cnh        from './tabs/Cnh/Cnh.vue'

export default {
  components: {
    Birthday,
    Financial,
    Schedules,
    Cnh
  },
  data () {
    return {
      mailConfig: true,
      automaticEmails: [],
      ready: false
    }
  },
  async created () {
    this.$vs.loading()
    const resp = await this.$http.get(`${process.env.VUE_APP_API_URL}/automaticEmails`)
    this.$nextTick(() => { this.$vs.loading.close() })
    this.mailConfig = resp.data.mailConfig
    this.automaticEmails = resp.data.automaticEmails
    this.ready = true
  }
}
</script>

<style lang="scss">
  #configuration-email-tab {
    .vs-tabs--content {
      padding: 0 10px 23px !important;
    }
  }
  .vs-tooltip {
    z-index: 99999;
  }
</style>
