var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "page-tree-account-plans" } },
    [
      _c(
        "dialog-sig",
        {
          attrs: {
            show: _vm.popupDeleteConfirm,
            title: "ALERTA",
            icon: "warning",
          },
          on: {
            action: function ($event) {
              return _vm.deleteAccountPlan(_vm.node)
            },
            cancel: function ($event) {
              _vm.popupDeleteConfirm = false
            },
          },
        },
        [
          _vm._v(
            "\n      Tem certeza que deseja excluir este Plano de Contas? "
          ),
          _c("br"),
          _vm._v(
            "\n      Esta ação é irreversível e pode afetar relatórios, estatísticas e gráficos.\n  "
          ),
        ]
      ),
      _c(
        "vx-card",
        [
          _c(
            "vs-button",
            {
              attrs: { "icon-pack": "feather", icon: "icon-plus-circle" },
              on: { click: _vm.addNode },
            },
            [_vm._v("Adicionar Plano de Contas Principal")]
          ),
          _c("vs-divider"),
          _c(
            "vue-tree-list",
            {
              attrs: {
                model: _vm.data,
                "default-tree-node-name": "Novo Plano de Conta",
                "default-leaf-node-name": "new leaf",
                "default-expanded": false,
              },
              on: {
                click: _vm.onClick,
                "change-name": _vm.onChangeName,
                "delete-node": _vm.onDel,
                "add-node": _vm.onAddNode,
                drop: _vm.onDropNode,
              },
              scopedSlots: _vm._u([
                {
                  key: "leafNameDisplay",
                  fn: function (slotProps) {
                    return [
                      _c("span", [
                        _vm._v(" " + _vm._s(slotProps.model.name) + " "),
                      ]),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "i",
                {
                  staticClass: "material-icons-outlined icon mt-0",
                  attrs: { slot: "addTreeNodeIcon", title: "Adicionar" },
                  slot: "addTreeNodeIcon",
                },
                [_vm._v("add_circle_outline")]
              ),
              _c(
                "i",
                {
                  staticClass: "material-icons-outlined icon",
                  attrs: { slot: "editNodeIcon", title: "Editar" },
                  on: {
                    click: function ($event) {
                      _vm.node = { changed: false }
                    },
                  },
                  slot: "editNodeIcon",
                },
                [_vm._v(" mode_edit_outline ")]
              ),
              _c(
                "i",
                {
                  staticClass: "material-icons-outlined icon",
                  attrs: { slot: "delNodeIcon", title: "Excluir" },
                  slot: "delNodeIcon",
                },
                [_vm._v(" delete_outline ")]
              ),
              _c(
                "i",
                {
                  staticClass: "material-icons-outlined icon",
                  attrs: { slot: "treeNodeIcon" },
                  slot: "treeNodeIcon",
                },
                [_vm._v(" folder_open ")]
              ),
              _c("span", {
                staticClass: "icon",
                attrs: { slot: "addLeafNodeIcon" },
                slot: "addLeafNodeIcon",
              }),
              _c("span", {
                staticClass: "icon",
                attrs: { slot: "leafNodeIcon" },
                slot: "leafNodeIcon",
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }