<template>
  <div id="configurations-schedule-tab">
    <vs-popup class="popup80" title="Nova Senha de Permissão" :active.sync="popupNewPassword">

      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mt-0">
          <label class="vs-input--label">Usuário</label>
          <v-select class="vue_select_drop_size_150" v-model="password.user_id" :reduce="option => option.value" :clearable="false"
              data-vv-as="Usuário" data-vv-scope="password" v-validate.initial="'required'" name="user_id"
              :options="users" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('password.user_id')">Campo Obrigatório.</span>
        </div>

        <div class="vx-col md:w-1/2 w-full mt-0">
          <label class="vs-input--label">Acesso</label>
          <v-select class="vue_select_drop_size_150" v-model="password.identification" :reduce="option => option.value" :clearable="false"
              data-vv-as="Tipo" data-vv-scope="password" v-validate.initial="'required'" name="identification"
              :options="[
                { value: 'SUPERVISOR', label: 'SUPERVISOR' }
              ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('password.identification')">Campo Obrigatório.</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mt-0">
          <vs-input class="w-full" label="Senha" v-model="password.password" data-vv-scope="password" data-vv-as="Senha" v-validate.initial="'required|min:6'" name="password" />
          <span class="text-danger text-sm" v-show="errors.has('password.password')">{{ errors.first('password.password') }}</span>
        </div>
        <div class="vx-col md:w-1/2 w-full mt-0">
          <label class="vs-input--label">Tipo</label>
          <v-select class="vue_select_drop_size_150" v-model="password.master" :reduce="option => option.value" :clearable="false"
              :options="[
                { value: true, label: 'MASTER' },
                { value: false, label: 'NORMAL' }
              ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button color="secondary" class="ml-auto" @click="popupNewPassword = false">Cancelar</vs-button>
              <vs-button class="ml-4" @click="save" :disabled="!validatePasswordForm">Salvar</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>

    <div class="vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex justify-between flex items-center">
      <h5 class="mb-1">Senhas de Segurança</h5>
      <vx-tooltip text="Cadastrar Nova Senha" position="top" class="ml-auto">
        <feather-icon icon="PlusCircleIcon" svgClasses="h-6 w-6 hover:text-primary cursor-pointer" @click="newPassword" />
      </vx-tooltip>
    </div>

    <vs-table noDataText="Nenhuma Senha Atribuída" :data="passwords" hoverFlat stripe style="overflow: -webkit-paged-y">
      <template slot="thead">
        <vs-th>Usuário</vs-th>
        <vs-th>Tipo de Senha</vs-th>
        <vs-th>Ações</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr :key="i" v-for="(password, i) in data" style="opacity: 1 !important">
          <vs-td>
            <span>{{ password && password.user ? password.user.name : 'Usuário excluído'}}</span>
          </vs-td>
          <vs-td>
            <span>{{ password.identification}}</span>
          </vs-td>
          <vs-td>
            <vx-tooltip text="Revogar Senha" position="left">
              <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDelete(password)" />
            </vx-tooltip>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

  </div>
</template>

<script>
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
export default {
  components: {
    vSelect
  },
  computed: {
    validatePasswordForm () {
      return !this.errors.any('password')
    }
  },
  data () {
    return {
      popupNewPassword: false,
      passwords: [],
      users: [],
      password:{
        id: null,
        identification: '',
        master: false,
        user_id: null,
        password: null
      }
    }
  },
  methods: {
    async save () {
      try {
        const idx = this.passwords.findIndex(d => d.user_id === this.password.user_id && d.identification === this.password.identification)
        if (idx >= 0) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: 'Este usuário já possui uma senha deste tipo. Por favor exclua a senha atual antes de cadastrar uma nova senha!',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }

        this.$vs.loading()
        const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/permissionPassword/store`, this.password)
        this.passwords.push(resp.data)
        this.popupNewPassword = false
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    confirmDelete (password) {
      this.password.id = password.id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: 'Tem certeza que deseja excluir esta senha?',
        accept: this.deletePassword,
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    async deletePassword () {
      try {
        this.$vs.loading()
        await this.$http.delete(`${process.env.VUE_APP_API_URL}/permissionPassword/${this.password.id}`)
        const idx = this.passwords.findIndex(d => d.id === this.password.id)
        if (idx >= 0) this.passwords.splice(idx, 1)
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Excluídos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.error(error)
        this.$vs.loading.close()
      }
    },
    newPassword () {
      this.password = {
        id: null,
        identification: '',
        master: false,
        user_id: null,
        password: null
      }
      this.popupNewPassword = true
    },
    async fetchPasswords () {
      const resp = await this.$http.get(`${process.env.VUE_APP_API_URL}/permissionPasswords`)
      this.passwords = resp.data
    },
    async fetchUsers () {
      const resp = await this.$http.get(`${process.env.VUE_APP_API_URL}/users/getAll`)
      for (const k in resp.data) {
        this.users.push({
          value: resp.data[k].id,
          label: `${resp.data[k].name}`
        })
      }
    }
  },

  created () {
    this.fetchPasswords()
    this.fetchUsers()
  }

}
</script>

<style>
#table_ th .vs-table-text {
    cursor: default;
    text-align: center !important;
    display: unset;
}
#configurations-schedule-tab td {
  border: 1px solid #c0c0c0;
  line-height: 1;
}
#configurations-schedule-tab td:hover {
  border-bottom: 1px solid #353434;
}
#configurations-schedule-tab td:first-child:hover {
  border-bottom: 1px solid #c0c0c0;
}
#configurations-schedule-tab .vs-con-table td:last-child {
  border-right: 1px solid transparent;
}
#configurations-schedule-tab .vs-table--thead {
  border: 1px solid transparent;
}
#configurations-schedule-tab .vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
    padding: 6px;
}
#configurations-schedule-tab .ps__scrollbar-y-rail {
  z-index: 99999;
}
</style>
