var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "configurations-general-configIbio-tab" } },
    [
      _c(
        "vs-popup",
        {
          attrs: {
            active: _vm.listSchedulePopup,
            title: "Aulas pendentes - não sincronizadas",
            fullscreen: "",
          },
          on: {
            "update:active": function ($event) {
              _vm.listSchedulePopup = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("h4", [
                _vm._v(
                  "As aulas abaixo não foram enviadas para o IBIO, portanto, serão removidas."
                ),
              ]),
              _c("h3", [
                _vm._v("Selecione as aulas que deseja manter no sistema."),
              ]),
              _c("vs-divider"),
              _c(
                "div",
                { staticClass: "flex flex-row-reverse" },
                [
                  _c("vs-button", { on: { click: _vm.syncIbioNow } }, [
                    _vm._v("Importar agenda do IBIO"),
                  ]),
                ],
                1
              ),
              _c("vs-divider"),
              _c("list-schedule-1", {
                attrs: { data: _vm.schedules },
                model: {
                  value: _vm.selectedSchedules,
                  callback: function ($$v) {
                    _vm.selectedSchedules = $$v
                  },
                  expression: "selectedSchedules",
                },
              }),
              _c("vs-divider"),
              _c(
                "div",
                { staticClass: "flex flex-row-reverse" },
                [
                  _c("vs-button", { on: { click: _vm.syncIbioNow } }, [
                    _vm._v("Importar agenda do IBIO"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "vx-row mt-4" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-1/2 w-full mt-0" },
          [
            _c("h3", { staticClass: "mt-2" }, [
              _vm._v("Alterar dados de login Ibio"),
            ]),
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    autocomplete: "off",
                    label: "Username",
                    "data-vv-scope": "configIbio",
                    "data-vv-as": "Username",
                    name: "login",
                  },
                  model: {
                    value: _vm.configIbio.login,
                    callback: function ($$v) {
                      _vm.$set(_vm.configIbio, "login", $$v)
                    },
                    expression: "configIbio.login",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("configIbio.login"),
                        expression: "errors.has('configIbio.login')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("configIbio.login")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    type: "password",
                    autocomplete: "off",
                    label: "Password (sua senha)",
                    "data-vv-scope": "configIbio",
                    "data-vv-as": "Password",
                    name: "password",
                  },
                  model: {
                    value: _vm.configIbio.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.configIbio, "password", $$v)
                    },
                    expression: "configIbio.password",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("configIbio.password"),
                        expression: "errors.has('configIbio.password')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("configIbio.password")))]
                ),
              ],
              1
            ),
            _c("vs-divider"),
            _c("div", { staticClass: "vx-row" }, [
              _c("div", { staticClass: "vx-col w-full" }, [
                _c(
                  "div",
                  {
                    staticClass: "mt-4 flex flex-wrap items-center justify-end",
                  },
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "mb-4",
                        attrs: { disabled: !_vm.validateconfigIbio },
                        on: { click: _vm.save },
                      },
                      [_vm._v("Salvar Configuração")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "vx-col md:w-1/2 w-full mt-0" }, [
          _c("h3", { staticClass: "mt-2" }, [_vm._v("Importação da Agenda")]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full mt-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "date_format:yyyy-MM-dd",
                      expression: "'date_format:yyyy-MM-dd'",
                    },
                  ],
                  ref: "startDate",
                  staticClass: "w-full",
                  attrs: {
                    type: "date",
                    label: "Data Inicial",
                    "data-vv-as": "Data Inicial",
                    name: "startDate",
                  },
                  model: {
                    value: _vm.startDate,
                    callback: function ($$v) {
                      _vm.startDate = $$v
                    },
                    expression: "startDate",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("startDate"),
                        expression: "errors.has('startDate')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Data inicial deve ser menor que a final")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full mt-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value:
                        "date_format:yyyy-MM-dd|dateGreaterOrEqualThan:startDate|maxDate:startDate,15,dias,days",
                      expression:
                        "'date_format:yyyy-MM-dd|dateGreaterOrEqualThan:startDate|maxDate:startDate,15,dias,days'",
                    },
                  ],
                  ref: "endDate",
                  staticClass: "w-full",
                  attrs: {
                    type: "date",
                    label: "Data Final",
                    "data-vv-delay": "500",
                    "data-vv-as": "Data Final",
                    name: "endDate",
                  },
                  model: {
                    value: _vm.endDate,
                    callback: function ($$v) {
                      _vm.endDate = $$v
                    },
                    expression: "endDate",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("endDate"),
                        expression: "errors.has('endDate')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("endDate")))]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                {
                  staticClass:
                    "mt-4 flex flex-wrap items-center justify-center",
                },
                [
                  _c("span", [
                    _vm._v(
                      "Última Sincronização:\n              " +
                        _vm._s(
                          _vm._f("dateTime")(
                            _vm.configIbio.last_update,
                            "dateTimeNoSecond",
                            "h"
                          )
                        )
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
          _vm.permitSyncOn
            ? _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-4 flex flex-wrap items-center justify-center",
                    },
                    [
                      _c(
                        "vx-tooltip",
                        {
                          attrs: {
                            text: "A sincronização está sendo executada em background. Só é permitido sincronizar agendas a cada 45 minutos. Atualize a página para tentar novamente!",
                            position: "top",
                          },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "mr-1",
                            attrs: { icon: "InfoIcon", svgClasses: "h-4 w-4" },
                          }),
                          _c("span", [
                            _vm._v(
                              "Nova Sincronização após:\n                " +
                                _vm._s(
                                  _vm._f("dateTime")(
                                    _vm.permitSyncOn,
                                    "dateTimeNoSecond",
                                    "h"
                                  )
                                )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                {
                  staticClass:
                    "mt-4 flex flex-wrap items-center justify-center",
                },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "w-80",
                      attrs: {
                        disabled:
                          !_vm.configIbio.id ||
                          _vm.permitSyncOn ||
                          !_vm.statusLoginIbio.status,
                      },
                      on: { click: _vm.fetchNotSyncedSchedules },
                    },
                    [_vm._v("Importar agenda do IBIO")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                {
                  staticClass:
                    "mt-4 flex flex-wrap items-center justify-center",
                },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "w-80",
                      attrs: { color: "danger", disabled: !_vm.configIbio.id },
                      on: { click: _vm.confirmDisable },
                    },
                    [_vm._v("Desabilitar importação")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-2",
          attrs: {
            color: "danger",
            title: "ATENÇÃO:",
            active: !_vm.statusLoginIbio.status,
            "icon-pack": "feather",
            "close-icon": "icon-x",
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.statusLoginIbio.message) + " "), _c("br")]
      ),
      _c(
        "vs-alert",
        {
          staticClass: "mt-2",
          attrs: {
            color: "success",
            title: "ATENÇÃO:",
            active: _vm.statusLoginIbio.status,
            "icon-pack": "feather",
            "close-icon": "icon-x",
          },
        },
        [
          _vm._v("\n    As configurações de login IBIO estão corretas. "),
          _c("br"),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }