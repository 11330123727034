var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "configurations-schedule-tab" } },
    [
      _c(
        "vs-table",
        {
          staticClass: "bordered",
          staticStyle: { overflow: "-webkit-paged-y" },
          attrs: { noDataText: "", data: [], hoverFlat: "" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({}) {
                return [
                  _c(
                    "vs-tr",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        opacity: "1 !important",
                      },
                    },
                    [
                      _c("vs-td", { staticStyle: { "text-align": "left" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [_vm._v("Agendamentos")]
                        ),
                      ]),
                      _c(
                        "vs-td",
                        [
                          _c("vs-textarea", {
                            staticClass: "mb-0",
                            attrs: { height: "100px", width: "100%" },
                            model: {
                              value:
                                _vm.configs.reporting_footer.practicalClass,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.configs.reporting_footer,
                                  "practicalClass",
                                  $$v
                                )
                              },
                              expression:
                                "configs.reporting_footer.practicalClass",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                _c("span", [_vm._v("Tipo de Relatório")]),
              ]),
              _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                _c("span", [_vm._v("Configuração de Rodapé")]),
              ]),
            ],
            1
          ),
        ],
        2
      ),
      _c("vs-divider"),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [_c("vs-button", { on: { click: _vm.save } }, [_vm._v("Salvar")])],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }