var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "birthday-automatic-email-tab" } },
    [
      !_vm.mailConfig
        ? _c(
            "vs-alert",
            {
              staticClass: "mt-2",
              attrs: {
                color: "primary",
                title: "Orientações:",
                active: !_vm.mailConfig,
                "icon-pack": "feather",
              },
            },
            [
              _c("p", [
                _vm._v(
                  "Para enviar emails é necessário configurar sua conta de email."
                ),
              ]),
              _c("p", [
                _vm._v(
                  "Nesta mesma tela, acesse a aba Geral e dentro desta nova tela, acesse a aba E-mail."
                ),
              ]),
              _c("p", [
                _vm._v(
                  "Efetue as configurações e retorne aqui para configurar o envio automático de e-mails."
                ),
              ]),
            ]
          )
        : _c(
            "div",
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-full w-full" },
                  [
                    _c(
                      "vs-checkbox",
                      {
                        staticClass: "ml-0 mr-1 mt-1",
                        model: {
                          value: _vm.configAutoSend.birthday.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.configAutoSend.birthday, "status", $$v)
                          },
                          expression: "configAutoSend.birthday.status",
                        },
                      },
                      [
                        _vm._v(
                          "Habilitar o envio automático de emails para aniversariantes."
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-3/4 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Enviar quantos dias antes?"),
                    ]),
                    _c("v-select", {
                      staticClass: "vue_select_drop_size_200",
                      attrs: {
                        appendToBody: "",
                        disabled: !_vm.configAutoSend.birthday.status,
                        multiple: "",
                        placeholder: "Selecione",
                        options: [
                          { value: "0", label: "No dia" },
                          { value: "1", label: "1 dia antes" },
                          { value: "2", label: "2 dias antes" },
                          { value: "3", label: "3 dias antes" },
                          { value: "4", label: "4 dias antes" },
                          { value: "5", label: "5 dias antes" },
                          { value: "6", label: "6 dias antes" },
                          { value: "7", label: "7 dias antes" },
                        ],
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n              Desculpe, nenhum resultado encontrado.\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.configAutoSend.birthday.daysBefore,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.configAutoSend.birthday,
                            "daysBefore",
                            $$v
                          )
                        },
                        expression: "configAutoSend.birthday.daysBefore",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Em que Horário?"),
                    ]),
                    _c("v-select", {
                      staticClass: "vue_select_drop_size_200",
                      attrs: {
                        appendToBody: "",
                        disabled: !_vm.configAutoSend.birthday.status,
                        placeholder: "Selecione",
                        reduce: (option) => option.value,
                        clearable: false,
                        options: [
                          { value: "06:00:00", label: "06:00h" },
                          { value: "21:30:00", label: "21:30h" },
                        ],
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n              Desculpe, nenhum resultado encontrado.\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.configAutoSend.birthday.time,
                        callback: function ($$v) {
                          _vm.$set(_vm.configAutoSend.birthday, "time", $$v)
                        },
                        expression: "configAutoSend.birthday.time",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-2/5 w-full" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v(
                        "Digite o texto utilizando o termo [nome_aluno] onde desejar mostrar o mesmo."
                      ),
                    ]),
                    _c("vs-textarea", {
                      staticClass: "mb-0",
                      attrs: {
                        disabled: !_vm.configAutoSend.birthday.status,
                        height: "100px",
                        width: "100%",
                      },
                      model: {
                        value: _vm.configAutoSend.birthday.text,
                        callback: function ($$v) {
                          _vm.$set(_vm.configAutoSend.birthday, "text", $$v)
                        },
                        expression: "configAutoSend.birthday.text",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-3/5 w-full" },
                  [
                    _c("h2", { staticClass: "text-center" }, [
                      _vm._v("Prévia"),
                    ]),
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      staticClass: "w-full mt-0",
                      attrs: {
                        label: "Assunto",
                        disabled: !_vm.configAutoSend.birthday.status,
                        "data-vv-scope": "birthday",
                        "data-vv-as": "Assunto",
                        name: "subject",
                      },
                      model: {
                        value: _vm.configAutoSend.birthday.subject,
                        callback: function ($$v) {
                          _vm.$set(_vm.configAutoSend.birthday, "subject", $$v)
                        },
                        expression: "configAutoSend.birthday.subject",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("birthday.subject"),
                            expression: "errors.has('birthday.subject')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("birthday.subject")))]
                    ),
                    _c(
                      "table",
                      {
                        staticStyle: { width: "650px" },
                        attrs: {
                          align: "center",
                          border: "0",
                          cellpadding: "0",
                          cellspacing: "0",
                        },
                      },
                      [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _c("img", {
                                attrs: {
                                  alt: "",
                                  height: "235",
                                  src: "https://app.sigcfc.com.br/assets/images/emailTemplate/birthday/2_01.jpg",
                                  width: "650",
                                },
                              }),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", [
                              _c(
                                "table",
                                {
                                  staticStyle: { width: "650px" },
                                  attrs: {
                                    align: "center",
                                    border: "0",
                                    cellpadding: "0",
                                    cellspacing: "0",
                                  },
                                },
                                [
                                  _c("tbody", [
                                    _c("tr", [
                                      _c("td", [
                                        _c("img", {
                                          attrs: {
                                            alt: "",
                                            height: "260",
                                            src: "https://app.sigcfc.com.br/assets/images/emailTemplate/birthday/2_02.jpg",
                                            width: "124",
                                          },
                                        }),
                                      ]),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "justify",
                                          },
                                          attrs: { width: "100%" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "#111111",
                                                "font-family":
                                                  "Verdana, Arial, Helvetica, sans-serif",
                                                "font-size": "13px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.configAutoSend.birthday
                                                    .text
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("td", [
                                        _c("img", {
                                          attrs: {
                                            alt: "",
                                            height: "260",
                                            src: "https://app.sigcfc.com.br/assets/images/emailTemplate/birthday/2_04.jpg",
                                            width: "140",
                                          },
                                        }),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", [
                              _c("img", {
                                attrs: {
                                  alt: "",
                                  height: "107",
                                  src: "https://app.sigcfc.com.br/assets/images/emailTemplate/birthday/2_05.jpg",
                                  width: "650",
                                },
                              }),
                            ]),
                          ]),
                          _vm.company.logo && _vm.company.logo.url
                            ? _c("tr", { attrs: { align: "center" } }, [
                                _c("td", [
                                  _c(
                                    "table",
                                    {
                                      staticClass: "mt-2",
                                      staticStyle: { width: "160px" },
                                      attrs: {
                                        align: "center",
                                        border: "0",
                                        cellpadding: "0",
                                        cellspacing: "0",
                                      },
                                    },
                                    [
                                      _c("tbody", [
                                        _c("tr", [
                                          _c(
                                            "td",
                                            { attrs: { align: "center" } },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  alt: "Logo",
                                                  height: "90",
                                                  src:
                                                    "https://app-sigcfc-bucket.s3-us-west-2.amazonaws.com/companyLogo/" +
                                                    _vm.company.logo.filename,
                                                },
                                              }),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _c("tr", [
                            _c("td", [
                              _c(
                                "table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    align: "center",
                                    border: "0",
                                    cellpadding: "0",
                                    cellspacing: "0",
                                  },
                                },
                                [
                                  _c("tbody", [
                                    _c("tr", [_c("td", [_c("hr")])]),
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: { "font-size": "10px" },
                                          attrs: { align: "center" },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticStyle: { color: "gray" },
                                              attrs: { href: "#" },
                                            },
                                            [
                                              _vm._v(
                                                "Não desejo mais receber notificações por email."
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("vs-divider"),
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap items-center justify-end" },
                    [
                      _c(
                        "vs-button",
                        {
                          attrs: { disabled: !_vm.validateForm },
                          on: {
                            click: function ($event) {
                              return _vm.save("birthday")
                            },
                          },
                        },
                        [_vm._v("Salvar configurações de aniversário")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }