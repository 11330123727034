var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "configuration-automatic-email-financial-tab" } },
    [
      _c(
        "vs-tabs",
        { staticClass: "tab-action-btn-fill-conatiner" },
        [
          _c(
            "vs-tab",
            {
              attrs: {
                label: "Contas à vencer",
                "icon-pack": "feather",
                icon: "icon-dollar-sign",
              },
            },
            [
              _c(
                "div",
                { staticClass: "tab-text" },
                [
                  _c("accounts-due", {
                    staticClass: "mt-4",
                    attrs: {
                      mailConfig: _vm.mailConfig,
                      automaticEmails: _vm.automaticEmails,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "vs-tab",
            {
              attrs: {
                label: "Contas vencidas",
                "icon-pack": "feather",
                icon: "icon-calendar",
              },
            },
            [
              _c(
                "div",
                { staticClass: "tab-text" },
                [
                  _c("overdue-accounts", {
                    staticClass: "mt-4",
                    attrs: {
                      mailConfig: _vm.mailConfig,
                      automaticEmails: _vm.automaticEmails,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }