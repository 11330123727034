function formatDateTime (dateTimeString, type = 'date') {
  if (type === 'date') { // A data passada deve estar no padrão: YYYY-MM-DD
    const [YYYY, MM, DD] = (dateTimeString.slice(0, 10)).split('-')
    return `${DD}/${MM}/${YYYY}`
  } else if (type === 'dateTime') { // A data passada deve estar no padrão: YYYY-MM-DD HH:mm:ss
    const [YYYY, MM, DD] = (dateTimeString.slice(0, 10)).split('-')
    const [H, m, s] = (dateTimeString.slice(11, 19)).split(':')
    return `${DD}/${MM}/${YYYY} ${H}:${m}:${s}`
  } else if (type === 'dateTimeNoSecond') { // A data passada deve estar no padrão: YYYY-MM-DD HH:mm:ss
    const [YYYY, MM, DD] = (dateTimeString.slice(0, 10)).split('-')
    const [H, m, s] = (dateTimeString.slice(11, 19)).split(':')
    return `${DD}/${MM}/${YYYY} ${H}:${m}`
  }
  return dateTimeString
}
export default formatDateTime
