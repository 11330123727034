<template>
  <div id="configurations-schedule-tab">
    <vs-popup class="popup90" :fullscreen="false" title="Boleto(s)" :active.sync="popupShowBoleto">
      <object :data="srcBoleto" width="100%" style="height: 600px" />
    </vs-popup>

    <div class="vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex justify-between flex items-center">
      <h5 class="mb-2">Boletos de Mensalidade Não Pagos</h5>
    </div>

    <vs-table noDataText="Nenhuma Mensalidade em Aberto" :data="transactions" hoverFlat stripe style="overflow: -webkit-paged-y">
      <template slot="thead">
        <vs-th>Situação</vs-th>
        <vs-th>Vencimento</vs-th>
        <vs-th>Boleto</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr :key="i" v-for="(transaction, i) in data" style="opacity: 1 !important" :class="[{'text-danger': transaction.situation === 'EXPIRED'}]">
          <vs-td>
            <div class="flex items-center">
              <feather-icon v-if="transaction.situation === 'EXPIRED'" icon="AlertCircleIcon" class="m-1 w-5 h-5"></feather-icon>
              <span >{{ transaction.situation === 'EXPIRED' ? 'VENCIDO' : 'À VENCER' }}</span>
            </div>
          </vs-td>
          <vs-td>
            <span>{{ transaction.expiration_date | dateTime('date') }}</span>
          </vs-td>
          <vs-td>
            <vx-tooltip text="Imprimir Boleto" position="left">
              <feather-icon icon="PrinterIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="print(transaction.boleto)" />
            </vx-tooltip>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

  </div>
</template>

<script>
export default {
  data () {
    return {
      popupShowBoleto: false,
      srcBoleto: '',
      transactions: []
    }
  },
  methods: {
    async fetchTransactions () {
      const resp = await this.$http.get(`${process.env.VUE_APP_API_URL}/getPlanPendingTransactions`)
      this.transactions = resp.data
    },
    async print (boletos) {
      this.$vs.loading()
      if (boletos.length) {
        const boleto = boletos[boletos.length - 1]
        const srcBoleto = await this.$http.post(`${process.env.VUE_APP_API_URL}/reprint/planBoleto/${boleto.id}`)
        this.srcBoleto = `data:application/pdf;base64,${srcBoleto.data}`
        this.popupShowBoleto = true
      }
      this.$vs.loading.close()
    }
  },

  created () {
    this.fetchTransactions()
  }

}
</script>

<style>
#table_ th .vs-table-text {
    cursor: default;
    text-align: center !important;
    display: unset;
}
#configurations-schedule-tab td {
  border: 1px solid #c0c0c0;
  line-height: 1;
}
#configurations-schedule-tab td:hover {
  border-bottom: 1px solid #353434;
}
#configurations-schedule-tab td:first-child:hover {
  border-bottom: 1px solid #c0c0c0;
}
#configurations-schedule-tab .vs-con-table td:last-child {
  border-right: 1px solid transparent;
}
#configurations-schedule-tab .vs-table--thead {
  border: 1px solid transparent;
}
#configurations-schedule-tab .vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
    padding: 6px;
}
#configurations-schedule-tab .ps__scrollbar-y-rail {
  z-index: 99999;
}
</style>
