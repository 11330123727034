<template>
  <div id="cnhs-automatic-email-tab">

      <vs-alert v-if="!mailConfig" class="mt-2" color="primary" title="Orientações:" :active="!mailConfig" icon-pack="feather">
        <p>Para enviar emails é necessário configurar sua conta de email.</p>
        <p>Nesta mesma tela, acesse a aba Geral e dentro desta nova tela, acesse a aba E-mail.</p>
        <p>Efetue as configurações e retorne aqui para configurar o envio automático de e-mails.</p>
      </vs-alert>

      <div v-else>
        <div class="vx-row">
          <div class="vx-col md:w-full w-full">
            <vs-checkbox v-model="configAutoSend.cnh.status" class="ml-0 mr-1 mt-1">Habilitar o envio automático de emails para CNHs.</vs-checkbox>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-full w-full">
            <vs-input class="w-full mt-0" label="Assunto" :disabled="!configAutoSend.cnh.status" v-model="configAutoSend.cnh.subject"
              data-vv-scope="cnh" data-vv-as="Assunto" v-validate.initial="'required'" name="subject" />
            <span class="text-danger text-sm" v-show="errors.has('cnh.subject')">{{ errors.first('cnh.subject') }}</span>
          </div>
        </div>
        <div class="vx-row">

          <div class="vx-col md:w-3/4 w-full mt-2">
            <label class="vs-input--label">Enviar quantos dias antes do vencimento?</label>
            <v-select appendToBody class="vue_select_drop_size_200" :disabled="!configAutoSend.cnh.status" v-model="configAutoSend.cnh.daysBefore"
              data-vv-scope="cnh" data-vv-as="Dias antes" v-validate.initial="'required'" name="daysBefore"
              multiple placeholder="Selecione"
              :options="[{ value: '1',  label: '1 dia antes do vencimento'   },
                         { value: '2',  label: '2 dias antes do vencimento'  },
                         { value: '3',  label: '3 dias antes do vencimento'  },
                         { value: '4',  label: '4 dias antes do vencimento'  },
                         { value: '5',  label: '5 dias antes do vencimento'  },
                         { value: '6',  label: '6 dias antes do vencimento'  },
                         { value: '7',  label: '7 dias antes do vencimento'  },
                         { value: '15', label: '15 dias antes do vencimento' },
                         { value: '30', label: '30 dias antes do vencimento' },
                         { value: '45', label: '45 dias antes do vencimento' },
                         { value: '60', label: '60 dias antes do vencimento' },
                         { value: '75', label: '75 dias antes do vencimento' },
                         { value: '90', label: '90 dias antes do vencimento' }]" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
            <span class="text-danger text-sm" v-show="errors.has('cnh.daysBefore')">{{ errors.first('cnh.daysBefore') }}</span>
          </div>
          <div class="vx-col md:w-1/4 w-full mt-2">
            <!-- ATENÇÃO, OS VALORES DOS HORÁRIOS ESTÃO CONFIGURADOS NO BANCO E USADOS NO CRON - NÃO ALTERAR AS OPÇÕES DE HORÁRIOS -->
            <label class="vs-input--label">Em que Horário?</label>
            <v-select appendToBody class="vue_select_drop_size_200" :disabled="!configAutoSend.cnh.status" v-model="configAutoSend.cnh.time"
              placeholder="Selecione" :reduce="option => option.value" :clearable="false"
              :options="[{ value: '06:00:00', label: '06:00h' },
                        { value: '21:30:00', label: '21:30h' } ]" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
        </div>
        <vs-divider></vs-divider>
        <div class="vx-row">
          <div class="vx-col md:w-2/5 w-full">
            <label class="vs-input--label">Digite o texto aqui utilizando o termo [nome_aluno] onde desejar mostrar o mesmo.</label>
            <vs-textarea :disabled="!configAutoSend.cnh.status" v-model="configAutoSend.cnh.text" class="mb-0" height="100px" width="100%" />
          </div>
          <div class="vx-col md:w-3/5 w-full">
            <h2 class="text-center">Prévia</h2>
            <table class="head-wrap w320 full-width-gmail-android" bgcolor="#f9f8f8" cellpadding="0" cellspacing="0" border="0" width="100%">
              <tr>
                <td background="http://app.sigcfc.com.br/assets/images/emailTemplate/stripes.png" bgcolor="#ffffff" width="100%" height="8" valign="top">
                  <!--[if gte mso 9]>
                  <v:rect xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style="mso-width-percent:1000;height:8px;">
                      <v:fill type="tile" src="/assets/images/emailTemplate/stripes.png" color="#ffffff" />
                      <v:textbox inset="0,0,0,0">
                  <![endif]-->
                  <div height="8"> </div>
                  <!--[if gte mso 9]>
                  </v:textbox>
                  </v:rect>
                  <![endif]-->
                </td>
              </tr>
              <tr class="header-background">
                  <td class="header container" align="center">
                    <div class="content">
                      <span class="brand">
                        <a href="#">{{company.trading_name}}</a>
                      </span>
                    </div>
                  </td>
              </tr>
            </table>
            <table class="body-wrap w320">
              <tr>
                <td></td>
                <td class="container">
                  <div class="content">
                    <table cellspacing="0">
                      <tr>
                        <td>
                          <table class="body">
                            <tr>
                              <td class="body-padding"></td>
                              <td class="body-padded">
                                <div class="body-title">
                                    {{configAutoSend.cnh.text}}
                                </div>
                              </td>
                              <td class="body-padding"></td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
                <td></td>
              </tr>
            </table>
            <hr>
            <table v-if="company.logo" class="footer-wrap w320 full-width-gmail-android" style="width: 100%;">
              <tr>
                <td align="center">
                  <img :alt="company.trading_name" height="90" :src="'https://app-sigcfc-bucket.s3-us-west-2.amazonaws.com/companyLogo/'+company.logo.filename">
                </td>
              </tr>
            </table>
          </div>
        </div>

        <vs-divider></vs-divider>

        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex flex-wrap items-center justify-end">
              <vs-button :disabled="!validateForm" @click="save('cnh')">Salvar configurações de CNH</vs-button>
            </div>
          </div>
        </div>
      </div>

  </div>
</template>

<script>
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')

export default {
  props: {
    mailConfig: {
      type: [Object, Boolean],
      default: false
    },
    automaticEmails: { type: Array, required: true }
  },
  components: {
    vSelect
  },
  computed: {
    validateForm () {
      return !this.errors.any('cnh')
    },
    company () {
      return this.$store.state.AppActiveUser.company
    }
  },
  data () {
    return {
      configAutoSend: {
        cnh: {
          status: false,
          identification: 'cnh',
          type: null,
          daysBefore: [{ value: '5', label: '5 dias antes' }],
          daysAfter: [],
          time: '21:30:00',
          subject: null,
          text: 'Olá [nome_aluno]'
        }
      }
    }
  },

  methods: {
    async save (identification) {
      const configAutoSend = {}
      Object.assign(configAutoSend, this.configAutoSend[identification])
      try {
        const response = await this.$http.put(`${process.env.VUE_APP_API_URL}/automaticEmail/`, configAutoSend)
        if (response.data.type === 'success') {
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: response.data.message,
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        } else if (response.data.type === 'warning') {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        }
      } catch (error) {
        console.log(error)
        this.$vs.notify({
          time: 5000,
          title: 'Erro',
          text: error.response.data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    }
  },
  async mounted () {
    this.automaticEmails.forEach(element => {
      if (element.identification === 'cnh') {
        this.configAutoSend.cnh = element
        this.configAutoSend.cnh.status = !!this.configAutoSend.cnh.status
      }
    })
  }
}
</script>

<style lang="scss">
  .vs-tooltip {
    z-index: 99999;
  }
</style>
