var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "configurations-schedule-tab" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup80",
          attrs: {
            title: "Nova Senha de Permissão",
            active: _vm.popupNewPassword,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupNewPassword = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Usuário"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "vue_select_drop_size_150",
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    "data-vv-as": "Usuário",
                    "data-vv-scope": "password",
                    name: "user_id",
                    options: _vm.users,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.password.user_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.password, "user_id", $$v)
                    },
                    expression: "password.user_id",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("password.user_id"),
                        expression: "errors.has('password.user_id')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Campo Obrigatório.")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Acesso"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "vue_select_drop_size_150",
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    "data-vv-as": "Tipo",
                    "data-vv-scope": "password",
                    name: "identification",
                    options: [{ value: "SUPERVISOR", label: "SUPERVISOR" }],
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.password.identification,
                    callback: function ($$v) {
                      _vm.$set(_vm.password, "identification", $$v)
                    },
                    expression: "password.identification",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("password.identification"),
                        expression: "errors.has('password.identification')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Campo Obrigatório.")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required|min:6",
                      expression: "'required|min:6'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    label: "Senha",
                    "data-vv-scope": "password",
                    "data-vv-as": "Senha",
                    name: "password",
                  },
                  model: {
                    value: _vm.password.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.password, "password", $$v)
                    },
                    expression: "password.password",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("password.password"),
                        expression: "errors.has('password.password')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("password.password")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Tipo"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_150",
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: [
                      { value: true, label: "MASTER" },
                      { value: false, label: "NORMAL" },
                    ],
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.password.master,
                    callback: function ($$v) {
                      _vm.$set(_vm.password, "master", $$v)
                    },
                    expression: "password.master",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupNewPassword = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      attrs: { disabled: !_vm.validatePasswordForm },
                      on: { click: _vm.save },
                    },
                    [_vm._v("Salvar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex justify-between flex items-center",
        },
        [
          _c("h5", { staticClass: "mb-1" }, [_vm._v("Senhas de Segurança")]),
          _c(
            "vx-tooltip",
            {
              staticClass: "ml-auto",
              attrs: { text: "Cadastrar Nova Senha", position: "top" },
            },
            [
              _c("feather-icon", {
                attrs: {
                  icon: "PlusCircleIcon",
                  svgClasses: "h-6 w-6 hover:text-primary cursor-pointer",
                },
                on: { click: _vm.newPassword },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "vs-table",
        {
          staticStyle: { overflow: "-webkit-paged-y" },
          attrs: {
            noDataText: "Nenhuma Senha Atribuída",
            data: _vm.passwords,
            hoverFlat: "",
            stripe: "",
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ data }) {
                return _vm._l(data, function (password, i) {
                  return _c(
                    "vs-tr",
                    { key: i, staticStyle: { opacity: "1 !important" } },
                    [
                      _c("vs-td", [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              password && password.user
                                ? password.user.name
                                : "Usuário excluído"
                            )
                          ),
                        ]),
                      ]),
                      _c("vs-td", [
                        _c("span", [_vm._v(_vm._s(password.identification))]),
                      ]),
                      _c(
                        "vs-td",
                        [
                          _c(
                            "vx-tooltip",
                            {
                              attrs: {
                                text: "Revogar Senha",
                                position: "left",
                              },
                            },
                            [
                              _c("feather-icon", {
                                attrs: {
                                  icon: "Trash2Icon",
                                  svgClasses:
                                    "h-5 w-5 hover:text-danger cursor-pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmDelete(password)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                })
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [_vm._v("Usuário")]),
              _c("vs-th", [_vm._v("Tipo de Senha")]),
              _c("vs-th", [_vm._v("Ações")]),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }