import Vue from 'vue'

export default {
  SET (state, payload) {
    state.contractModel = Object.assign({}, state.contractModel, payload) //precisa ser assim para manter a reatividade
  },
  ADD (state, payload) {
    state.contractModels.push(payload) //precisa ser assim para manter a reatividade
  },
  //SUBSTITUI O ARRAY DE CONTAS POR UM NOVO
  REFRESH (state, payload) {
    state.contractModels = payload
  },
  DELETE (state, payload) {
    const idx1 = state.contractModels.findIndex(o => o.id === payload)
    if (idx1 >= 0) state.contractModels.splice(idx1, 1) //precisa ser assim para manter a reatividade

    const idx = state.contractsGrid.data.findIndex(o => o.id === payload)
    if (idx >= 0) {
      state.contractsGrid.data.splice(idx, 1) //precisa ser assim para manter a reatividade
      state.contractsGrid.total = state.contractsGrid.total - 1
    }
  },
  ////////////////////
  SET_CONTRACTS_GRID (state, payload) {
    state.contractsGrid = payload
  },
  STORE (state, payload) {
    state.contractModels.push(payload) //precisa ser assim para manter a reatividade
    state.contractModels.sort((a, b) => { return a.title.localeCompare(b.title) }) // Ordena por nome

    state.contractsGrid.data.push(payload)
    state.contractsGrid.data.sort((a, b) => { return a.title.localeCompare(b.title) }) // Ordena por nome
    state.contractsGrid.total = state.contractsGrid.total + 1
  },
  UPDATE (state, payload) {
    const idx = state.contractModels.findIndex(o => o.id === payload.id)
    if (idx >= 0) Vue.set(state.contractModels, idx, payload)

    const idx1 = state.contractsGrid.data.findIndex(o => o.id === payload.id)
    if (idx1 >= 0) Vue.set(state.contractsGrid.data, idx1, payload)
  }
}
