var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "configuration-email-tab" } }, [
    _c("div", { staticClass: "vx-row mt-4" }, [
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mt-0" },
        [
          _c("h3", { staticClass: "mt-2" }, [_vm._v("Configuração de E-mail")]),
          _c(
            "div",
            { staticClass: "vx-col md:w-full w-full mt-0" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.initial",
                    value: "required",
                    expression: "'required'",
                    modifiers: { initial: true },
                  },
                ],
                staticClass: "w-full mt-0",
                attrs: {
                  label: "Host (smtp.dominio.com.br)",
                  "data-vv-scope": "mailConfiguration",
                  "data-vv-as": "Host",
                  name: "mail_host",
                },
                model: {
                  value: _vm.mailConfiguration.mail_host,
                  callback: function ($$v) {
                    _vm.$set(_vm.mailConfiguration, "mail_host", $$v)
                  },
                  expression: "mailConfiguration.mail_host",
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("mailConfiguration.mail_host"),
                      expression: "errors.has('mailConfiguration.mail_host')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [
                  _vm._v(
                    _vm._s(_vm.errors.first("mailConfiguration.mail_host"))
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-full w-full mt-0" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.initial",
                    value: "required",
                    expression: "'required'",
                    modifiers: { initial: true },
                  },
                ],
                staticClass: "w-full mt-0",
                attrs: {
                  type: "number",
                  label: "Porta (465/587)",
                  "data-vv-scope": "mailConfiguration",
                  "data-vv-as": "Porta",
                  name: "mail_port",
                },
                model: {
                  value: _vm.mailConfiguration.mail_port,
                  callback: function ($$v) {
                    _vm.$set(_vm.mailConfiguration, "mail_port", $$v)
                  },
                  expression: "mailConfiguration.mail_port",
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("mailConfiguration.mail_port"),
                      expression: "errors.has('mailConfiguration.mail_port')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [
                  _vm._v(
                    _vm._s(_vm.errors.first("mailConfiguration.mail_port"))
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-full w-full mt-0" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.initial",
                    value: "required|email",
                    expression: "'required|email'",
                    modifiers: { initial: true },
                  },
                ],
                staticClass: "w-full mt-0",
                attrs: {
                  label: "Email de envio (seu@email.com.br)",
                  "data-vv-scope": "mailConfiguration",
                  "data-vv-as": "Email de envio",
                  name: "mail_from_address",
                },
                model: {
                  value: _vm.mailConfiguration.mail_from_address,
                  callback: function ($$v) {
                    _vm.$set(_vm.mailConfiguration, "mail_from_address", $$v)
                  },
                  expression: "mailConfiguration.mail_from_address",
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has(
                        "mailConfiguration.mail_from_address"
                      ),
                      expression:
                        "errors.has('mailConfiguration.mail_from_address')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.errors.first("mailConfiguration.mail_from_address")
                    )
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-full w-full mt-0" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.initial",
                    value: "required",
                    expression: "'required'",
                    modifiers: { initial: true },
                  },
                ],
                staticClass: "w-full mt-0",
                attrs: {
                  label: "Nome (nome para envio)",
                  "data-vv-scope": "mailConfiguration",
                  "data-vv-as": "Nome",
                  name: "mail_from_name",
                },
                model: {
                  value: _vm.mailConfiguration.mail_from_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.mailConfiguration, "mail_from_name", $$v)
                  },
                  expression: "mailConfiguration.mail_from_name",
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("mailConfiguration.mail_from_name"),
                      expression:
                        "errors.has('mailConfiguration.mail_from_name')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [
                  _vm._v(
                    _vm._s(_vm.errors.first("mailConfiguration.mail_from_name"))
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-full w-full mt-0" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.initial",
                    value: "required",
                    expression: "'required'",
                    modifiers: { initial: true },
                  },
                ],
                staticClass: "w-full mt-0",
                attrs: {
                  label: "Criptografia (ssl/tls)",
                  "data-vv-scope": "mailConfiguration",
                  "data-vv-as": "Criptografia",
                  name: "mail_encryption",
                },
                model: {
                  value: _vm.mailConfiguration.mail_encryption,
                  callback: function ($$v) {
                    _vm.$set(_vm.mailConfiguration, "mail_encryption", $$v)
                  },
                  expression: "mailConfiguration.mail_encryption",
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has(
                        "mailConfiguration.mail_encryption"
                      ),
                      expression:
                        "errors.has('mailConfiguration.mail_encryption')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.errors.first("mailConfiguration.mail_encryption")
                    )
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-full w-full mt-0" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.initial",
                    value: "required",
                    expression: "'required'",
                    modifiers: { initial: true },
                  },
                ],
                staticClass: "w-full mt-0",
                attrs: {
                  label: "Username (seu@email.com.br)",
                  "data-vv-scope": "mailConfiguration",
                  "data-vv-as": "Username",
                  name: "mail_username",
                },
                model: {
                  value: _vm.mailConfiguration.mail_username,
                  callback: function ($$v) {
                    _vm.$set(_vm.mailConfiguration, "mail_username", $$v)
                  },
                  expression: "mailConfiguration.mail_username",
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("mailConfiguration.mail_username"),
                      expression:
                        "errors.has('mailConfiguration.mail_username')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [
                  _vm._v(
                    _vm._s(_vm.errors.first("mailConfiguration.mail_username"))
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-full w-full mt-0" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.initial",
                    value: "required",
                    expression: "'required'",
                    modifiers: { initial: true },
                  },
                ],
                staticClass: "w-full mt-0",
                attrs: {
                  type: "password",
                  label: "Password (sua senha)",
                  "data-vv-scope": "mailConfiguration",
                  "data-vv-as": "Password",
                  name: "mail_password",
                },
                model: {
                  value: _vm.mailConfiguration.mail_password,
                  callback: function ($$v) {
                    _vm.$set(_vm.mailConfiguration, "mail_password", $$v)
                  },
                  expression: "mailConfiguration.mail_password",
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("mailConfiguration.mail_password"),
                      expression:
                        "errors.has('mailConfiguration.mail_password')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [
                  _vm._v(
                    _vm._s(_vm.errors.first("mailConfiguration.mail_password"))
                  ),
                ]
              ),
            ],
            1
          ),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      attrs: { color: "danger" },
                      on: { click: _vm.confirmDelete },
                    },
                    [_vm._v("Deletar Configuração")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      attrs: { disabled: !_vm.validateMailConfiguration },
                      on: { click: _vm.save },
                    },
                    [_vm._v("Salvar Configuração")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mt-0" },
        [
          _c(
            "vs-alert",
            {
              staticClass: "mt-2",
              attrs: {
                color: "primary",
                title: "Orientações:",
                active: _vm.activeAlert,
                closable: "",
                "icon-pack": "feather",
                "close-icon": "icon-x",
              },
              on: {
                "update:active": function ($event) {
                  _vm.activeAlert = $event
                },
              },
            },
            [
              _c("p", [
                _vm._v(
                  "Se você utilizar uma conta do Gmail ou kinghost deve seguir os tutoriais descritos abaixo antes de enviar malas diretas pelo SIGCFC."
                ),
              ]),
              _c("p", [
                _vm._v("Se você utilizar uma conta do Gmail clique "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://sigcfc.com.br/como-configurar-o-envio-de-emails-por-contas-gmail-no-sigcfc/",
                      target: "_blank",
                    },
                  },
                  [_vm._v("aqui")]
                ),
                _vm._v(" para acessar ao tutorial."),
              ]),
              _c("p", [
                _vm._v("Se você utilizar uma conta da kinghost clique "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://sigcfc.com.br/como-configurar-o-envio-de-emails-por-contas-da-kinghost/",
                      target: "_blank",
                    },
                  },
                  [_vm._v("aqui")]
                ),
                _vm._v(" para acessar ao tutorial."),
              ]),
            ]
          ),
          _c("h3", { staticClass: "mt-2" }, [
            _vm._v("Teste de configuração de email"),
          ]),
          _c(
            "div",
            { staticClass: "vx-col md:w-full w-full mt-0" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.initial",
                    value: "required|email",
                    expression: "'required|email'",
                    modifiers: { initial: true },
                  },
                ],
                staticClass: "w-full mt-0",
                attrs: {
                  label: "Enviar teste para",
                  "data-vv-scope": "testConfiguration",
                  "data-vv-as": "Email",
                  name: "email",
                },
                model: {
                  value: _vm.testConfiguration.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.testConfiguration, "email", $$v)
                  },
                  expression: "testConfiguration.email",
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("testConfiguration.email"),
                      expression: "errors.has('testConfiguration.email')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first("testConfiguration.email")))]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-full w-full mt-0" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.initial",
                    value: "required",
                    expression: "'required'",
                    modifiers: { initial: true },
                  },
                ],
                staticClass: "w-full mt-0",
                attrs: {
                  label: "Assunto",
                  "data-vv-scope": "testConfiguration",
                  "data-vv-as": "Assunto",
                  name: "subject",
                },
                model: {
                  value: _vm.testConfiguration.subject,
                  callback: function ($$v) {
                    _vm.$set(_vm.testConfiguration, "subject", $$v)
                  },
                  expression: "testConfiguration.subject",
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("testConfiguration.subject"),
                      expression: "errors.has('testConfiguration.subject')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first("testConfiguration.subject")))]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-full w-full mt-0" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.initial",
                    value: "required",
                    expression: "'required'",
                    modifiers: { initial: true },
                  },
                ],
                staticClass: "w-full mt-0",
                attrs: {
                  label: "Mensagem",
                  "data-vv-scope": "testConfiguration",
                  "data-vv-as": "Mensagem",
                  name: "message",
                },
                model: {
                  value: _vm.testConfiguration.message,
                  callback: function ($$v) {
                    _vm.$set(_vm.testConfiguration, "message", $$v)
                  },
                  expression: "testConfiguration.message",
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("testConfiguration.message"),
                      expression: "errors.has('testConfiguration.message')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first("testConfiguration.message")))]
              ),
            ],
            1
          ),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      attrs: { disabled: !_vm.validateTestConfiguration },
                      on: { click: _vm.sendTestMail },
                    },
                    [_vm._v("Enviar email de teste")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }